// Store the URL fragment in a cookie to make it accessible to the server.
// This ensures the server can preserve the fragment when redirecting to the same page
// (i.e., on current permission or location change)
// since browsers do not include fragments in the HTTP Referer header.
export default function initUrlFragmentInCookie() {
  function setUrlFragmentInCookie() {
    document.cookie = "urlFragment=" + window.location.hash + "; path=/";
  }
  window.addEventListener("hashchange", setUrlFragmentInCookie);
  setUrlFragmentInCookie();
}
