import { get } from 'helpers/core/ajax-utils'
import CalmSelect from 'components/common/CalmSelect.vue'

export default {
  components: { CalmSelect },
  data() {
    return {
      venue_id: undefined,
      event_type: undefined,
      event_template_id: undefined,
      template_name: undefined,
      availableTemplates: []
    }
  },
  computed: {
    templatesUrl() {
      if (!this.venue_id || !this.event_type) return null

      return this.$routes.find_templates_for_venue_and_event_type_path({
        venue_id: this.venue_id,
        event_type: this.event_type
      })
    }
  },
  watch: {
    templatesUrl(url) {
      if (!url) return

      get(url).then((response) => {
        this.availableTemplates = response.data
      })
    }
  }
}
