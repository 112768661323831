<template>
  <form ref="danasForm" :action="$routes.dana_bulk_save_danas_path()" method="post">
    <input v-if="csrfParam() && csrfToken()" :name="csrfParam()" :value="csrfToken()" type="hidden">
    <input type="hidden" name="danas" :value="JSON.stringify(danas)">

    <div class="row mb-3">
      <div class="col-md-10">
        <h1>{{ $t('lookups.dana_bulk.dana_bulk') }}</h1>
      </div>
      <div class="col-md-2 text-end">
        <a class="btn btn-primary" :href="$routes.jano_new_person_path()" target="_blank">
          {{ $t("formtastic.actions.add_person") }}
        </a>
      </div>
    </div>

    <!-- Configuration -->
    <div class="row">
      <!-- Location -->
      <div class="col-md-2">
        <label class="control-label">{{ $t('activerecord.attributes.dana.location') }}</label>
        <calm-select v-model="location" name="location" :options="locations" />
      </div>

      <template v-if="location">
        <!-- Course | Non Course -->
        <div class="col-md-2">
          <label class="control-label">
            {{ $t('activerecord.attributes.dana.course_or_non_course') }}
          </label>
          <calm-select
            v-model="danaType"
            name="danaType"
            :options="danaTypes"
            :disabled="eventList.length == 0"
          />
        </div>
        <!-- Event -->
        <div v-if="danaType == 'course'" class="col-md-3">
          <label class="control-label">{{ $t('activerecord.attributes.dana.event_id') }}</label>
          <calm-select v-model="event" name="event" :options="eventList" />
        </div>
        <!-- Bank Account -->
        <div v-if="bankAccountList.length > 0" class="col-md-3">
          <label class="control-label">{{ $t('activerecord.attributes.dana.bank_account') }}</label>
          <calm-select v-model="bankAccount" name="bankAccount" :options="bankAccountList" />
        </div>
      </template>
    </div>
    <hr>

    <template v-if="addDanaReady">
      <!-- Default Values -->
      <div class="row">
        <div class="col-md-5">
          <label class="control-label">
            {{ $t('activerecord.attributes.dana.default_values') }}</label>
        </div>
        <div class="col-md-1">
          <label class="control-label">
            {{ $t('activerecord.attributes.dana.amount_currency') }}</label>
          <calm-select v-model="defaultCurrency" :options="currencyList" />
        </div>
        <div class="col-md-2">
          <label class="control-label">{{ $t('activerecord.attributes.dana.category') }}</label>
          <calm-select v-model="defaultCategory" :options="categoriesList" />
        </div>
        <div class="col-md-2">
          <label class="control-label">
            {{ $t('activerecord.attributes.dana.transaction_type') }}</label>
          <calm-select v-model="defaultTransactionType" :options="transactionTypeList" />
        </div>
        <div class="col-md-2">
          <label class="control-label">
            {{ $t('activerecord.attributes.dana.transaction_date') }}</label>
          <input v-model="transactionDate" class="form-control" type="date">
        </div>
      </div>
      <hr>

      <!-- Dana Headers -->
      <div class="row">
        <div class="col-md-4">
          <label class="control-label">{{ $t('simple_form.labels.defaults.name') }}</label>
        </div>
        <div class="col-md-1">
          <label class="control-label">{{ $t('activerecord.attributes.dana.amount') }}</label>
        </div>
        <div class="col-md-1">
          <label class="control-label">
            {{ $t('activerecord.attributes.dana.amount_currency') }}
          </label>
        </div>
        <div class="col-md-2">
          <label class="control-label">
            {{ $t('activerecord.attributes.dana.category') }}
          </label>
        </div>
        <div class="col-md-2">
          <label class="control-label">
            {{ $t('activerecord.attributes.dana.transaction_type') }}
          </label>
        </div>
        <div class="col-md-2">
          <label class="control-label">
            {{ $t('activerecord.attributes.dana.transaction_date') }}
          </label>
        </div>
      </div>

      <!-- Danas List -->
      <div v-for="(row,index) in danas" :key="row.id">
        <danas-row
          v-model="danas[index]"
          :transaction-date="transactionDate"
          :transaction-type-list="transactionTypeList"
          :currency-list="currencyList"
          :category-list="categoriesList"
          @row-deleted="removeRow(row)"
        />
      </div>

      <!-- Actions -->
      <div class="mt-3">
        <button class="btn btn-dark me-2" type="button" @click="addRow()">
          {{ $t('buttons.dana.add_dana') }}
        </button>
        <button
          v-show="danas.length > 0"
          ref="saveDanaButton"
          class="btn btn-success"
          type="button"
          @click="saveAllDana"
        >
          {{ $t('buttons.dana.save_all_dana') }}
        </button>
      </div>
    </template>
  </form>
</template>

<script>
import DanasRow from 'components/dana_bulk/DanasRow.vue'
import CalmSelect from 'components/common/CalmSelect'
import { axios, handleAjaxError } from 'helpers/core/ajax-utils'

export default {
  components: { DanasRow, CalmSelect },
  props: {
    locations: {
      type: Array,
      required: true
    },
    danaTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      location: null,
      danaType: null,
      event: null,
      eventList: [],
      transactionDate: null,
      defaultTransactionType: '',
      transactionTypeList: [],
      defaultCategory: '',
      categoriesList: [],
      defaultCurrency: '',
      currencyList: [],
      bankAccount: '',
      bankAccountList: [],
      danas: [],
      currentId: 0
    }
  },
  computed: {
    addDanaReady() {
      return this.location && this.danaType && (this.event || this.danaType == 'non_course')
    }
  },
  methods: {
    getEvents() {
      axios.get(this.$routes.dana_bulk_events_path(this.location, { format: 'json' }))
        .then((result) => {
          this.eventList = result.data
          if (result.data.length == 0) this.danaType = 'non_course'
        }).catch((error) => handleAjaxError(error, this))
    },
    getTransactionTypes() {
      axios.get(this.$routes.dana_bulk_trans_types_path(this.location, { format: 'json' }))
        .then((result) => {
          this.transactionTypeList = result.data
        }).catch((error) => handleAjaxError(error, this))
    },
    getCategories() {
      axios.get(this.$routes.dana_bulk_categories_path(this.location, { format: 'json' }))
        .then((result) => {
          this.categoriesList = result.data
        }).catch((error) => handleAjaxError(error, this))
    },
    getCurrencies() {
      axios.get(this.$routes.dana_bulk_currencies_path(this.location, { format: 'json' }))
        .then((result) => {
          this.currencyList = result.data
        }).catch((error) => handleAjaxError(error, this))
    },
    getBankAccounts() {
      axios.get(this.$routes.dana_bulk_bank_accounts_path(this.location, { format: 'json' }))
        .then((result) => {
          this.bankAccountList = result.data
        }).catch((error) => handleAjaxError(error, this))
    },
    getDefaults() {
      axios.get(this.$routes.dana_bulk_default_values_path(this.location, { format: 'json' }))
        .then((result) => {
          this.defaultCurrency = result.data.currency
          this.defaultCategory = result.data.category
          this.defaultTransactionType = result.data.transaction_type
          this.bankAccount = result.data.bank_account
        }).catch((error) => handleAjaxError(error, this))
    },
    addRow() {
      this.currentId += 1
      this.danas.push({
        id: this.currentId,
        personId: undefined,
        amount: undefined,
        transactionType: this.defaultTransactionType,
        category: this.defaultCategory,
        currency: this.defaultCurrency,
        transactionDate: this.transactionDate
      })
    },
    removeRow(row) {
      this.danas.splice(this.danas.indexOf(row), 1)
    },
    csrfToken() {
      const meta = document.querySelector('meta[name=csrf-token]')
      return meta ? meta.content : null
    },
    csrfParam() {
      const meta = document.querySelector('meta[name=csrf-param]')
      return meta ? meta.content : null
    },
    saveAllDana() {
      if (this.danas.find((d) => d.personId
                                 && (!d.amount
                                   || d.amount <= 0
                                   || !d.currency
                                   || !d.category
                                   || !d.transactionType
                                   || !d.transactionDate))) {
        return calmAlertBootstrap({
          type: 'alert',
          messageText: `Sorry, there are some missing fields,
          please correct them before saving the data`
        })
      }

      this.$refs.saveDanaButton.disabled = true
      this.$refs.danasForm.submit()
    }
  },
  watch: {
    location(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getTransactionTypes()
        this.getCategories()
        this.getCurrencies()
        this.getBankAccounts()
        this.getDefaults()
        this.getEvents()
      }
    },
    danas: {
      deep: true,
      handler() {
        if (this.danas.length > 0 && this.danas.at(-1).personId) this.addRow()
      }
    }
  }
}
</script>
