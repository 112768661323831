import initDatatable from './publication_subscription_datatable'

export default function() {
  setTimeout(disablePreventLocation, 0)
  $('#person_prevent_subscription_location_ids').change(disablePreventLocation)
  initDatatable()
}

function disablePreventLocation() {
  const $preventLocSelect = $('#person_prevent_subscription_location_ids')
  if ($preventLocSelect.length > 0) {
    const disabledOptions = $preventLocSelect.data('disabled-options').split(',')
    const $select2 = $preventLocSelect.siblings('.select2-container')

    disabledOptions.forEach((name) => {
      $select2.find(`[title="${name}"]`).find('.select2-selection__choice__remove').remove()
    })
  }
}
