<template>
  <div>
    <div class="reorder-header">
      <h2>{{ $t('menus.waiting_list_reorder') }}</h2>
      <p class="hint">
        <small>{{ $t('lookups.drag_and_drop_reorder_hint') }}</small>
      </p>
      <SaveControls
        :modified="modified"
        :loading="saving"
        @save="save"
        @undo="undo"
      />
    </div>
    <CourseApplicationsReorderTable
      :course-applications="courseApplications"
      :course-id="courseId"
      :loading="fetching"
      @reorder="reorder"
    >
      <template #empty>
        <div class="text-center">
          <template v-if="leaf">
            {{ $t('messages.waiting_list_reordering.empty') }}
          </template>
          <template v-else>
            {{ $t('messages.waiting_list_reordering.not_leaf') }}
          </template>
        </div>
      </template>
    </CourseApplicationsReorderTable>
  </div>
</template>

<script>
import { get, put, handleAjaxError } from 'helpers/core/ajax-utils'
import CourseApplicationsReorderTable from './CourseApplicationsReorderTable.vue'

export default {
  components: { CourseApplicationsReorderTable },
  data() {
    return {
      savedCourseApplications: [],
      courseApplications: [],
      courseId: undefined,
      modified: false,
      saving: false,
      fetching: false,
      category: '',
      leaf: false
    }
  },
  created() {
    this.savedCourseApplications = gon.course_applications
    this.courseApplications = gon.course_applications
    this.category = gon.category
    this.courseId = gon.course_id
    window.quotaTree.bindOnClick(this.onQuotaTreeClick)
    window.quotaTree.setCategory(this.category)
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.confirmLeaving)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.confirmLeaving)
  },
  computed: {
    route() {
      return this.getRoute(this.courseId, this.category)
    }
  },
  methods: {
    reorder(courseApplications) {
      this.modified = true
      this.courseApplications = courseApplications
    },
    save() {
      this.saving = true
      this.update(this.route)
        .then((result) => {
          this.savedCourseApplications = result.data
          this.courseApplications = result.data
          this.modified = false
        })
        .finally(() => { this.saving = false })
    },
    undo() {
      this.courseApplications = this.savedCourseApplications
      this.modified = false
    },
    update(route) {
      return put(route, { course_applications: this.courseApplications.map((ca) => ca.id) })
        .catch((error) => {
          handleAjaxError(error, this)
        })
    },
    onQuotaTreeClick(node) {
      // set leaf first as category's watcher use it
      this.leaf = node.leaf === ''
      this.category = node.category
    },
    confirmLeaving(event) {
      if (!this.modified) return

      // ask confirmation before unloading the page
      event.preventDefault() // for firefox
      event.returnValue = '' // for chrome
    },
    maybeUpdateBeforeLeaving(category) {
      return new Promise((resolve, reject) => {
        if (window.confirm('Do you want to save your changes?')) {
          this.update(this.getRoute(this.courseId, category))
            .then(() => resolve(true))
            .catch((error) => reject(error))
        } else {
          resolve(false)
        }
      })
    },
    getRoute(courseId, category) {
      return this.$routes.event_course_applications_reorder_path(courseId, category)
    }
  },
  watch: {
    category(category, oldCategory) {
      if (category === oldCategory || !oldCategory) return

      if (this.modified) {
        this.maybeUpdateBeforeLeaving(oldCategory).catch(() => { })
      }

      this.modified = false
      this.courseApplications = []

      const { route } = this
      window.history.replaceState({}, '', route)

      if (!this.leaf) return

      this.fetching = true
      get(route)
        .then((result) => {
          this.courseApplications = result.data
        })
        .catch((error) => {
          handleAjaxError(error, this)
        })
        .finally(() => { this.fetching = false })
    }
  }
}
</script>

<style scoped lang="scss">
.reorder-header {
  display: flex;
  padding-bottom: 4px;

  .help-block {
    align-self: flex-end;
  }
}
</style>
