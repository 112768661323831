<template>
  <div class="letter-task-confirmation">
    <!-- refs #4962 we ask that the person signs off that they have completed
     the task b4 they can remove from inbox -->
    <p>{{ $t("lookups.user.reminders.remove_inbox_hint") }}</p>
    <input v-model="signature">
    <br>
    <br>
    <button class="btn btn-primary" type="button" @click.stop="taskCompleted">
      {{ $t("lookups.user.reminders.task_completed") }}
    </button>
    <span>&nbsp;</span>
    <button class="btn btn-danger" type="button" @click.prevent.stop="hideModal">
      {{ $t("lookups.actions.close") }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    letter: {
      type: Object,
      required: true
    }
  },
  emits: ['update:letter', 'close'],
  data() {
    return { signature: '' }
  },
  methods: {
    taskCompleted() {
      const url = this.$routes.comm_completed_task_remove_from_inbox_transmission_path(this.letter.id)
      $.ajax(url, {
        type: 'PATCH',
        dataType: 'json',
        data: { signature: this.signature },
        success: (data) => {
          this.$emit('update:letter', data.letter)
        }
      })
      this.$emit('close') // hide the modal
    },
    hideModal() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="scss">
  button {
    margin-right: 20px;
  }
  input {
    width: 100%;
    border-radius: 5px;
    border-color: grey;
    color: black;
  }
</style>
