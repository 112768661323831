<template>
  <div class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" aria-label="Close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">
            {{ title }}
          </h4>
        </div>
        <div class="modal-body">
          <!-- Wait for the modal to be displayed to mount the children -->
          <!-- Useful for AvailableLetterTemplates for example so the ajax call -->
          <!-- are run only when needed -->
          <template v-if="visible">
            <slot />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return { visible: false }
  },
  methods: {
    show() {
      $('.modal:visible').modal('hide')
      $(this.$el).modal('show')
      this.visible = true
    },
    hide() {
      $(this.$el).modal('hide')
      this.visible = false
    }
  }
}
</script>
