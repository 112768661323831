<template>
  <div class="referral-state">
    <h4>{{ $t("lookups.referral_list.current_referral_state") + ' : ' + stateName }}</h4>

    <div v-if="isEarlyLongCourse" class="alert alert-warning">
      {{ $t("lookups.course_app_referral_state.no_rsl_processing_early_lc") }}
    </div>
    <div v-else class="rsl-actions-container">
      <a
        v-show="rslActionAvailable('set_rl_to_clear')"
        class="btn btn-default"
        @click="setToClear"
      >
        {{ $t("lookups.course_app_referral_state_actions.set_to_clear") }}
      </a>

      <span v-show="rslActionAvailable('send_to_at')">
        <a
          v-remote-modal
          class="btn btn-info"
          :href="endpoints.draftToAtRsl"
        >
          {{ $t("lookups.course_app_referral_state_actions.send_to_at") }}
        </a>
      </span>

      <span v-if="onSl == 'true'" v-show="rslActionAvailable('at_approves')">
        <a
          v-remote-modal
          class="btn btn-success"
          :href="endpoints.draftAtApproves"
          :data-confirm="$t('lookups.referral_entry.confirm_contacted_sl_assignor1')
            + '<br>' + $t('lookups.referral_entry.confirm_contacted_sl_assignor2')"
          data-confirm_v_remote_modal="true"
        >
          {{ $t("lookups.course_app_referral_state_actions.at_approves") }}
        </a>
      </span>

      <!-- above refs #5243 see Note 6 -->
      <span v-if="!(onSl == 'true')" v-show="rslActionAvailable('at_approves')">
        <a
          v-remote-modal
          class="btn btn-success"
          :href="endpoints.draftAtApproves"
        >
          {{ $t("lookups.course_app_referral_state_actions.at_approves") }}
        </a>
      </span>

      <span v-show="rslActionAvailable('at_refuses')">
        <a
          v-remote-modal
          class="btn btn-success"
          :href="endpoints.draftAtRefuses"
        >
          {{ $t("lookups.course_app_referral_state_actions.at_refuses") }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    appId: {
      type: [String, Number],
      required: true
    },
    forAt: {
      type: String,
      default: ''
    },
    onSl: {
      type: String,
      default: ''
    },
    isEarlyLongCourse: Boolean,
    initialData: {
      type: Object,
      default: () => ({})
    },
    toAtRslTopic: {
      type: String,
      default: ''
    },
    courseAppRegOnlyTopic: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      state: '',
      stateName: '',
      allowedActions: []
    }
  },
  mounted() {
    // we provide initial values from the view to avoid make an ajax request to initialize them
    this.updateStateData(this.initialData)
  },
  computed: {
    endpoints() {
      return {
        draftToAtRsl: this.$routes.comm_new_internal_message_path({
          course_application_id: this.appId,
          referral_action_on_send: 'send_to_at',
          to_at_only: true,
          system_template: 'rsl_to_at',
          topic: this.toAtRslTopic
        }),
        draftAtApproves: this.$routes.comm_new_internal_message_path({
          course_application_id: this.appId,
          referral_action_on_send: 'at_approves',
          to_reg_only: true,
          system_template: 'rsl_at_approves',
          topic: this.courseAppRegOnlyTopic
        }),
        draftAtRefuses: this.$routes.comm_new_internal_message_path({
          course_application_id: this.appId,
          referral_action_on_send: 'at_refuses',
          to_reg_only: true,
          system_template: 'rsl_at_refuses',
          topic: this.courseAppRegOnlyTopic
        })
      }
    }
  },
  methods: {
    rslActionAvailable(actionName) {
      return this.allowedActions.includes(actionName)
    },
    updateStateData(data) {
      this.stateName = data.rsl_state_name
      this.allowedActions = data.allowed_rsl_state_actions || []
      if (!['Not Checked', 'Referral', 'Please Check Manually'].includes(this.stateName)) {
        $('a[href="#rsl-tab"]').removeClass('need-process')
      }
      if (!this.$can('at_approves', 'messages')) {
        this.allowedActions = this.allowedActions.filter((a) => a != 'at_approves')
      }
      if (!this.$can('at_refuses', 'messages')) {
        this.allowedActions = this.allowedActions.filter((a) => a != 'at_refuses')
      }
    },
    setToClear() {
      $.post(
        this.$routes.course_application_change_rl_state_path(this.appId),
        { ref_list_state_event: 'manual_ref_list_check', on_ref_list: 'false' },
        (data) => { this.updateStateData(data) },
        'json'
      )
    }
  }
}
</script>

<style scoped lang="scss">
  .referral-state {
    margin-bottom: 10px;
    .state-name {
      margin-left: 15px;
    }
    .rsl-actions-container {
      margin: 15px 0;
      .btn { margin-right: 10px; }
    }
  }
</style>
