<template>
  <InputText
    v-model="(filterModel || {}).value"
    @input="filterCallback()"
  />
</template>

<script>
import InputText from 'primevue/inputtext'

export default {
  components: { InputText },
  props: {
    filterModel: {
      type: Object,
      default: () => ({})
    },
    filterCallback: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
