import { post } from 'helpers/core/ajax-utils'

export default function() {
  if (!gon.event_id) return console.error('export_event#index: gon - event_id or url missing')

  $('#change-arrived-to-completed').on('click', function() {
    if (gon.child_course == 'true') return
    // prevent if people on RSL
    if (document.querySelectorAll('#export-event-index-dt-scroll-cont .person-on-rsl').length > 0) {
      return alertBootstrap(window.t('lookups.export_event.action_prevented_by_rsl_alerts'))
    }
    // Ask confirmation
    if (!confirm(window.t('messages.export_event.change_arrived_to_completed_warning'))) return

    $(this).attr('disabled', true)
    post(Routes.change_arrived_to_completed_path(gon.locale, gon.event_id))
      .then(() => document.location.reload())
      .catch((e) => alert(e))
  })

  setListenersExportTreeRelevantFilters()
  retrieveExportTreeAjax()
}

function retrieveExportTreeAjax() {
  const ajaxUrl = $('div#interactive-export-tree').data('ajaxUrl')
  const courseAppId = $('div#interactive-export-tree').data('courseAppId')
  const data = {
    url: ajaxUrl,
    data: { course_app_id: courseAppId },
    async: true,
    type: 'GET',
    dataType: 'json',
    success(data, textStatus, xhr) {
      handleAjaxSuccess(data, textStatus, xhr)
    },
    error(xhr, textStatus, error) {
      handleAjaxError(xhr, textStatus, error)
    }
  }
  $.ajax(data)
}

function clearExportInfoDisplay() {
  $('div#quota-summaries div#parent div#name').html('')
  $('div#quota-summaries div#parent div#description').html('')
  $('div#quota-summaries div#child1 div#name').html('')
  $('div#quota-summaries div#child1 div#description').html('')
  $('div#quota-summaries div#child2 div#name').html('')
  $('div#quota-summaries div#child2 div#description').html('')
  $('div#quota-summaries').hide()
}

function handleAjaxSuccess(data, textStatus, xhr) {
  setupTree(data)
  bindTreeToAppFilter()
}

function handleAjaxError(xhr, textStatus, error) {
  alert(`Error loading export tree ${error}`)
}

function setupTree(data) {
  const quotaTreeData = JSON.parse(data.quota_tree)
  const emphasizedNodePath = data.quota_tree_emphasized_node
  $('#interactive-export-tree').tree({
    data: quotaTreeData,
    autoOpen: 15,
    dragAndDrop: false,
    useContextMenu: false,
    onCreateLi(node, $li) {
      // setup the data attributes for filtering
      const filterData = node.filter_data
      if (filterData) {
        const sittingFilterStr = filterData.sitting
        const fullTimeFilterStr = filterData.full_time
        const oldNewFilterStr = filterData.old
        const genderFilterStr = filterData.gen
        const languageFilterStr = filterData.lang
        if (typeof (sittingFilterStr) !== 'undefined') {
          $li.data('sittingFilter', sittingFilterStr)
        }
        if (typeof (fullTimeFilterStr) !== 'undefined') {
          $li.data('fullTimeFilter', fullTimeFilterStr)
        }
        if (typeof (oldNewFilterStr) !== 'undefined') {
          $li.data('oldNewFilter', oldNewFilterStr)
        }
        if (typeof (genderFilterStr) !== 'undefined') {
          $li.data('genderFilter', genderFilterStr)
        }
        if (typeof (languageFilterStr) !== 'undefined') {
          $li.data('languageFilter', languageFilterStr)
        }
      }
      let emphasisClass = ''
      const { category } = node
      const fadeOutClass = 'fade-out'
      if (emphasizedNodePath && category != emphasizedNodePath) emphasisClass = fadeOutClass
      else if (emphasizedNodePath) emphasisClass = 'emphasized'
      // Add 'icon' span before title
      const title = `${category} : ${node.description}`
      const completed_numbers_span = `<span class="rsl-review-num"> ${node.completed}</span>`
      // var exported_numbers_span = '<span class="exported-num"> ' + node.exported +'</span>';
      const status_review_numbers_span = `<span class="status-review-num">${node.status_review}</span>`
      // var exportable_numbers_span = '<span class="exportable-num">'+ node.exportable +'</span>';
      const rsl_review_numbers_span = `<span class="completed-num">${node.rsl_review}</span>`
      // var non_exportable_numbers_span = '<span class="non-exportable-num">' + node.non_exportable + '</span>';
      const numbers_span = `<span class="quota-numbers" title="${title}"class="jqtree_common">`
                        + ` ${rsl_review_numbers_span} - ${status_review_numbers_span} - ${completed_numbers_span}</span>`
      $li.find('.jqtree-title').after(numbers_span)
      $li.addClass(emphasisClass)
      let classStr = ''
      if (emphasisClass !== fadeOutClass) classStr = node.traffic_light_class
      if (node.alert_class) classStr += (` ${node.alert_class}`)
      $li.find('span.completed-num').addClass(classStr)
      $li.find('span.status-review-num').addClass(classStr)
      $li.find('span.rsl-review-num').addClass(classStr)
      $li.attr('id', category.replace(/\./g, '-')) // add an id to the li equal to the category of the node, which is always unique
    }

  })
}

function bindTreeToAppFilter() {
  // if the page being displayed is the export event index page then bind clicking nodes and leaves to
  // course application filtering in the datatable
  if ($('div#export-event-index-page').length > 0) {
    $('#interactive-export-tree').find('li').on(
      'click',
      function(event) {
        const el = $(this)
        calmApp.exportEvent.exportTreeNodeClicked(el)
        event.preventDefault() // prevents the select/deselect node action - we take care of it by adding class
        event.stopPropagation()
        unhighlightNode(false)
        el.addClass('jqtree-selected') // previously clicking on the node twice would remove the selected field - do not allow this
        clearExportInfoDisplay()
      }
    )
  }
}
function getSelectedNodes() {
  return $('li.jqtree-selected')
}

function unhighlightNode(notifyExporteventIndexBool) {
  getSelectedNodes().removeClass('jqtree-selected')
  // also need to nullify the lastExportTreeNodeIdClicked variable in the calmApp.exportEvent for state history sake
  if (notifyExporteventIndexBool) calmApp.exportEvent.exportTreeNodeUnselected()
}

// when on the course application list view- clicking a node adds values to the filters- if the value in one of these filters
// is subsequently changed we need to unhighlight any highlighted nodes as the data in the course app list will no longer
// represent the node's relevant course aplications
function setListenersExportTreeRelevantFilters() {
  $('td.app-search input').keyup(function() {
    if (getSelectedNodes().length > 0) {
      const relevantInputNames = calmApp.exportEvent.getExportTreeColumnNames()
      if (relevantInputNames.indexOf(this.name) > -1) {
        unhighlightNode(true)
        clearExportInfoDisplay()
      }
    }
  })
}
