import initPersonTab from 'jano/components/people/person_details_tab'
import initDanaTable from 'components/danas/dana_datatable'
import initDanaTab from 'components/danas/form'
import initSubscriptionTab from 'news/components/publication_subscription_tab'
import LettersTable from 'components/letters/LettersTable'

export default {
  components: { LettersTable },
  data: {
    person: {},
    priority_note_editable: false,
    priority_note: undefined
  },
  created() {
    this.person = gon.person
    this.priority_note = this.person.priority_note
    this.priority_note_editable = gon.priority_note_editable
  },
  mounted() {
    initPersonTab()
    calmApp.peopleSearch.initialize()
    initDanaTab()
    initDanaTable()
    initSubscriptionTab()
    calmApp.unsavedChanges.initUnsavedChanges()
    calmApp.datepicker.initializeAfterVueMounted()

    $('a[role=tab][href="#letters"]').on('shown.bs.tab', () => this.$refs.lettersTable.onShow())
  },
  methods: {
    updateLettersDatatable(openLastAdded = false) {
      if (this.$refs.lettersTable) this.$refs.lettersTable.fetchLetters(openLastAdded)
    }
  }
}
