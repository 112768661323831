export default function initLocaleSwitchLinks() {
  $('[data-switch-locale-to]').on('click', function(event) {
    event.preventDefault();
    const url = new URL(window.location.href);
    const oldLocale = url.pathname.split('/').filter(Boolean)[0];
    const newLocale = $(this).data('switch-locale-to');
    url.searchParams.set('save_interface_lang', 'true');
    Turbo.visit(url.toString().replace(`/${oldLocale}/`, `/${newLocale}/`));
  });
}
