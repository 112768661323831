import 'jqtree'

$(document).on('calm:turbo:load', () => {
  initQuotaTree()
})

export default function initQuotaTree() {
  if ($('#interactive-quota-tree').length > 0) {
    window.quotaTree = new QuotaTree()
  }
}

class QuotaTree {
  constructor() {
    this.category = null
    this.onClick = null
    this.eventId = this.$domElement.data('eventId')
    this.courseAppId = this.$domElement.data('courseAppId')
    this.appStateGroup = ''
    this.setListenersQuotaTreeRelevantFilters()
    this.retrieveQuotaTreeAjax()
  }

  get $domElement() {
    return $('#interactive-quota-tree')
  }

  bindOnClick(callback) {
    this.onClick = callback
  }

  setCategory(category) {
    this.category = category
  }

  retrieveQuotaTreeAjax() {
    const data = {
      url: Routes.event_quota_tree_path(gon.locale, this.eventId),
      data: { course_app_id: this.courseAppId },
      async: true,
      type: 'GET',
      dataType: 'json',
      success: (data, textStatus, xhr) => {
        this.handleAjaxSuccess(data, textStatus, xhr)
      },
      error: (xhr, textStatus, error) => {
        this.handleAjaxError(xhr, textStatus, error)
      }
    }
    $.ajax(data)
  }

  handleAjaxSuccess(data, textStatus, xhr) {
    this.setupTree(data)
    this.bindTreeToAppFilter()
    // The node selected is stored inside hash : #enrolled_reserved@sitting.male
    const { hash } = document.location
    if (hash.includes('@')) $(`li[data-category="${hash.split('@')[1]}"]`).trigger('click')
  }

  handleAjaxError(xhr, textStatus, error) {
    alert(`Error loading quota tree ${error}`)
  }

  setupTree(data) {
    const quotaTreeData = JSON.parse(data.quota_tree)
    const emphasizedNodePath = data.quota_tree_emphasized_node
    this.appStateGroup = data.app_state_group
    const self = this
    this.$domElement.tree('destroy')
    this.$domElement.tree({
      data: quotaTreeData,
      autoOpen: 15,
      dragAndDrop: false,
      useContextMenu: false,
      onCreateLi(node, $li) {
        // setup the data attributes for filtering
        const filterData = node.filter_data
        if (filterData) {
          const sittingFilterStr = filterData.sitting
          const fullTimeFilterStr = filterData.full_time
          const oldNewFilterStr = filterData.old
          const genderFilterStr = filterData.gen
          const languageFilterStr = filterData.lang
          if (typeof (sittingFilterStr) !== 'undefined') {
            $li.data('sittingFilter', sittingFilterStr)
          }
          if (typeof (fullTimeFilterStr) !== 'undefined') {
            $li.data('fullTimeFilter', fullTimeFilterStr)
          }
          if (typeof (oldNewFilterStr) !== 'undefined') {
            $li.data('oldNewFilter', oldNewFilterStr)
          }
          if (typeof (genderFilterStr) !== 'undefined') {
            $li.data('genderFilter', genderFilterStr)
          }
          if (typeof (languageFilterStr) !== 'undefined') {
            $li.data('languageFilter', languageFilterStr)
          }
        }
        let emphasisClass = ''
        const { category } = node
        const li = $li.get(0)
        const leaf = !node.children.length
        Object.entries(filterData).forEach(([prop, value]) => li.dataset[prop] = value)
        if (leaf) {
          // HTML data attributes are strings, to set a boolean use the attribute presence/absence
          li.dataset.leaf = ''
        }
        li.dataset.category = category
        if (self.category === category) {
          $li.addClass('jqtree-selected')
          self.onClick({ category, leaf })
        }
        const fadeOutClass = 'fade-out'
        if (emphasizedNodePath && category != emphasizedNodePath) {
          emphasisClass = fadeOutClass
        } else if (emphasizedNodePath) {
          emphasisClass = 'emphasized'
        }
        // Add 'icon' span before title
        let title = `${category} : ${node.description}`
        if (node.reservations_hint) { title += `\r\n\r\n(${node.reservations_hint})` }
        const reserved_numbers_span = `<span class="quota-numbers" title="${title}"class="jqtree_common"><span class="quota-num"> ${node.quota}</span>`
        let waitlist_numbers_span = ''
        let tent_numbers_span = ''
        let rand_select_open_span = ''
        let no_vac_numbers_span = ''
        let manual_indicator = ''
        // only show wait list numbers at node if there is more than 0 applications on the wait list
        // but refs #5348 waitlist numbers like 0(+2)/8 should be shown where +2 is the number of reservations hence `== '('`
        if (node.waitlist_numbers[0] !== '0' || node.waitlist_numbers[1] == '+') {
          // waitlist_numbers_span = '<span class="wl-quota-num"> ('+ node.waitlist_numbers +')</span></span>';
          waitlist_numbers_span = `<span class="wl-quota-num"> (${node.waitlist_numbers})</span>`
        }
        if (node.quota_tent !== '0') {
          tent_numbers_span = `<span class="tent-quota-num" title="${node.quota_tent_hint}">
            <i class="glyphicon glyphicon-tree-conifer"></i>
            <span>${node.quota_tent}</span>
          </span>`
        }
        if (node.no_vacancy !== '0') {
          no_vac_numbers_span = `<span class="no-vac-quota-num" title="${node.no_vacancy_hint}">${node.no_vacancy}</span>`
        }
        if (node.quota_open_for_rand_select) {
          rand_select_open_span = `<span class="rand-select-open glyphicon glyphicon-random" title="${node.quota_rand_select_hint}"></span>`
        }
        if (!node.hasChildren() && node.manual_traffic_lights) {
          let mnClassStr = 'manual-indicator '
          if (node.manual_traffic_light_class && emphasisClass !== fadeOutClass) {
            mnClassStr += node.manual_traffic_light_class
          }
          manual_indicator = `<span class="${mnClassStr}" title="${node.manual_traffic_light_title}"></span>` // style="float:right;margin-right:15%"

          // add a sign on the quota tree indicating that the lights are managed manually if it has not already been added
          if ($('.manual-ind-span').length == 0) {
            const $manualIndSpan = $('<span>', { class: 'glyphicon glyphicon-wrench manual-ind-span', title: 'manually managed traffic lights' })
            // var $manualIndSpan = $("<span>", {'class':"glyphicon glyphicon-road"});
            $('#interactive-quota-tree-container h4').append($manualIndSpan)
          }
        }
        $li.find('.jqtree-title').after(reserved_numbers_span + waitlist_numbers_span + tent_numbers_span + no_vac_numbers_span + manual_indicator + rand_select_open_span)
        $li.addClass(emphasisClass)
        let classStr = ''
        if (emphasisClass !== fadeOutClass) {
          classStr = node.traffic_light_class
        }
        if (node.alert_class) {
          classStr += (` ${node.alert_class}`)
        }
        $li.find('span.quota-num').addClass(classStr)
        $li.find('span.wl-quota-num').addClass(classStr)
        $li.find('span.tent-quota-num').addClass(classStr)
        if (classStr.indexOf('add-from-no-vacancy') > 0 && $li.find('span.wl-quota-num').length > 0) { //  && $li.find('span.wl-quota-num').is(":visible") // this commented out check for visibility seemed to be unneeded and also did not work
          $li.find('span.quota-num').removeClass('add-from-no-vacancy') // avoids double asterisk when wl quota is shown
        }
        // $li.find('span.manual-indicator').addClass(classStr);
        $li.attr('id', category.replace(/\./g, '-')) // add an id to the li equal to the category of the node, which is always unique
      }

    })
  }

  bindTreeToAppFilter() {
    // if the page being displayed is the course applications index page then bind clicking nodes and leaves to
    // course application filtering in the datatable
    const self = this
    this.$domElement.find('li').on(
      'click',
      function(event) {
        event.preventDefault() // prevents the select/deselect node action - we take care of it by adding class
        event.stopPropagation()
        const el = $(this)
        if (self.onClick) {
          self.onClick(this.dataset)
          self.unhighlightNode(false)
          el.addClass('jqtree-selected') // previously clicking on the node twice would remove the selected field - do not allow this
        } else if ($('table#random-select-datatable').length > 0) {
          calmApp.randomSelectDatatable.quotaTreeNodeClicked(el)
          self.unhighlightNode(false)
          el.addClass('jqtree-selected') // previously clicking on the node twice would remove the selected field - do not allow this
        } else {
          let path = Routes.event_course_applications_path(gon.locale, { event_id: self.eventId })
          path += `#${self.appStateGroup}@${this.dataset.category}`
          Turbo.visit(path)
        }
      }
    )
  }

  getSelectedNodes() {
    return $('li.jqtree-selected')
  }

  unhighlightNode(notifyCourseAppIndexBool) {
    this.getSelectedNodes().removeClass('jqtree-selected')
  }

  // when on the course application list view- clicking a node adds values to the filters- if the value in one of these filters
  // is subsequently changed we need to unhighlight any highlighted nodes as the data in the course app list will no longer
  // represent the node's relevant course aplications
  setListenersQuotaTreeRelevantFilters() {
    const self = this
    const quotaTreeColumnNames = ['gender', 'sitting', 'full_time', 'old'] // + conversation_locale as of #1481 ? //columns that the quota tree uses to filter on when node clicked 'accommodation' too in calm 4 maybe'
    $('td.app-search input').keyup(function() {
      if (self.getSelectedNodes().length > 0) {
        if (quotaTreeColumnNames.indexOf(this.name) > -1) {
          self.unhighlightNode(true)
        }
      }
    })
  }
}
