export default {
  el: '#new-custom-external',
  data: {
    content: undefined,
    message_type: undefined
  },
  mounted() {
    $('.switch-toggle input[checked]').trigger('click') // fix switch when loaded by ajax
  }
}
