export default function() {
  const datatableSettings = {
    ...calmApp.dtSettings.getCalmDatatableSettings(),
    ...{
      language: calmApp.dtSettings.getDTLangTranslations(),
      columns: [
        { className: 'max-width-200 allow-word-wrap' },
        { className: 'max-width-200 allow-word-wrap' },
        { className: 'max-width-200 allow-word-wrap' },
        { className: 'max-width-200 allow-word-wrap' },
        { className: 'max-width-200 allow-word-wrap' },
        { className: 'max-width-200 allow-word-wrap', sortable: false, searchable: false },
        { className: 'max-width-200 allow-word-wrap', sortable: false, searchable: false }
      ],
      responsive: { breakpoints: calmApp.dtSettings.getResponsiveBreakpoints() }
    }
  }
  $('#mail-servers-datatable').dataTable(datatableSettings)
}
