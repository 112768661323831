/* eslint-disable no-inner-declarations */
import { createVueApp } from 'helpers/core/vue-custom'
import SegmentsRenderer from 'components/letters/SegmentsRenderer'

// javascript used in the cell version of the location variables helper - soon to replace the old versions
export default function initLocationVarHelper() {
  if ($('#loc-vars-helper').length > 0) {
    const helperDiv = $('#loc-vars-helper')
    const expandAjaxUrl = helperDiv.data('ajaxUrl')
    const expandLanguageSelect = $('.expand-language').find('select')
    const expandLocationSelect = $('.expand-location').find('select')
    const $expandGenderSelect = $('.expand-gender').find('select')
    const $expandConfirmationStateNameSelect = $('.expand-confirmation-state-name').find('select')
    const $expandCrsParticpationSelect = $('.expand-course-participation').find('select')
    const $expandEventTypeSelect = $('.expand-event-type').find('select')
    const modelType = $('#loc-vars-helper').data('modelType')
    const isShowView = $('#loc-vars-helper').data('isShowView')
    const englishId = $('#form-for-loc-var-helper').data('englishId')

    expandLanguageSelect.change(() => {
      getExpandedValueAjax()
    })
    expandLocationSelect.change(() => {
      getExpandedValueAjax()
    })
    $expandConfirmationStateNameSelect.change(() => {
      getExpandedValueAjax()
    })
    $expandGenderSelect.change(() => {
      getExpandedValueAjax()
    })
    $expandCrsParticpationSelect.change(() => {
      getExpandedValueAjax()
    })
    $expandEventTypeSelect.change(() => {
      getExpandedValueAjax()
    })
    $('a[role="tab"][href="#letter"]').click(() => {
      getExpandedValueAjax()
    })

    const previewer = createVueApp({
      components: { SegmentsRenderer },
      data() {
        return {
          labels: { name: '', description: '' },
          name: '',
          description: '',
          segments: [],
          ready: false
        }
      },
      template: `<div class="message-preview">
        <div><b>{{ labels.name }}:</b> {{ name }}</div>
        <div class="mb-3"><b>{{ labels.description }}:</b> {{ description }}</div>
        <SegmentsRenderer :segments="segments" action="edit"/>
        <div v-if="!ready" class="overlay"><i class="fas fs-1 opacity-50 fa-circle-notch fa-spin"></i></div>
      </div>`
    }).mount('#message-preview')

    // retrieve the text to expand and name and description from a the current page
    function getTranslationData(langId) {
      const result = { expandText: '', name: '', description: '' }
      const gender = $('#expand_gender').val()
      const $panel = $(`.letter-translation-show-hide-fields[data-language-id=${langId}]`)

      // now find the wysiwyg text from either the male_addressee or the female addressee
      if ($panel) {
        result.expandText = $panel.find(`.read-only-text.${gender}_addressee`).html()
        result.expandText ||= $panel.find(`.${gender}_addressee`).val()
        // Fallback to male
        if (gender == 'female' && isEmptyText(result.expandText)) {
          result.expandText = $panel.find('.read-only-text.male_addressee').html()
          result.expandText ||= $panel.find('.male_addressee').val()
        }
        // get the name and description too
        result.name = $panel.find('.translated-name-input').val()
        result.description = $panel.find('.translated-descr-input').val()
      }
      return result
    }

    function isEmptyText(inputText) {
      // Refs #5870 re <div><br></div>
      return inputText
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/&nbsp;/g, '')
        .replace(/[\r\n]+/g, '').length == 0
    }

    function retrieveExpandTextFromView(expandLanguageId) {
      expandLanguageId = parseInt(expandLanguageId)
      const enData = englishData()
      if (expandLanguageId !== englishId) {
        const translationExpandData = getTranslationData(expandLanguageId)
        const translationEmpty = isEmptyText(translationExpandData.expandText)
        return {
          expandText: translationEmpty ? enData.expandText : translationExpandData.expandText,
          name: translationExpandData.name || enData.name,
          description: translationExpandData.description || enData.description
        }
      }
      return enData
    }

    function englishData() {
      // .val() is for edit view, the field is a textarea
      // .html() is for show view, the field is a div
      return {
        expandText: $('.english-translation').val() || $('.english-translation').html(),
        name: $('#display-name-input').val(),
        description: $('#description-input').val()
      }
    }

    function getExpandedValueAjax(firstInit = false) {
      // when editing the textarea, the expand language id are changed, but we don't
      // want this to trigger a update of the expand value
      if (!$('#expanded-template-section').is(':visible')) return

      const expandLanguageId = expandLanguageSelect.val()
      const expandLocationId = expandLocationSelect.val()
      const appData = {
        male: $expandGenderSelect.val() === 'male',
        confirmation_state_name: $expandConfirmationStateNameSelect.val(),
        course_participations: [$expandCrsParticpationSelect.val()],
        event_type: $expandEventTypeSelect.val()
      }

      const expandData = retrieveExpandTextFromView(expandLanguageId)
      if (firstInit) expandData.expandText = null // #3841, on first init tinyMCE is not yet initialized and the expandText is not properly formated, so we set it to null and get it from the backend

      previewer.ready = false

      $.ajax({
        url: expandAjaxUrl,
        type: 'POST',
        dataType: 'json',
        data: {
          expand_text: expandData.expandText,
          expand_language_id: expandLanguageId,
          expand_location_id: expandLocationId,
          template_code: $('#name-input').val(),
          name: expandData.name,
          description: expandData.description,
          from_show_or_edit_view: isShowView ? 'show' : 'edit',
          app_data: appData,
          model_type: modelType
        },
        success(data, textStatus, xhr) {
          handleAjaxSuccess(data)
        },
        error(request, status, error) {
          if (request.status == 401) {
            // This must be a session timeout by devise so reload page to get devise to ask user to sign in again
            location.reload()
          } else {
            alertBootstrap(request.responseText)
          }
        }
      })
    }

    function handleAjaxSuccess(data) {
      const expandedTextSection = $('#expanded-template-section')
      previewer.labels = {
        name: expandedTextSection.data('nameLabel'),
        description: expandedTextSection.data('descrLabel')
      }
      previewer.segments = data.segments || []
      previewer.name = data.name
      previewer.description = data.description
      previewer.ready = true

      if (data.lv_info) {
        displayLocationVarInfo(data.lv_info)
      }
    }

    function displayLocationVarInfo(lv_data) {
      const lvTable = $('table#loc-vars')
      lvTable.find('tbody').empty()
      if (lv_data.length > 0) {
        $('#loc-vars-display').show()
      } else {
        $('#loc-vars-display').hide()
      }
      // Populate the location variable information table by creating rows
      for (let i = 0; i < lv_data.length; i++) {
        const lv = lv_data[i]
        const tr = $('<tr/>')
        // adding special style when translation is missing
        if (lv.missing_translation) tr.addClass('missing-translation-row')
        const nameTd = $('<td/>')
        let nameTxt = ''
        if (lv.warning) {
          nameTxt += `${lv.warning} ` // add any warnings to the name column
        }
        nameTxt += lv.name
        nameTd.text(nameTxt)
        nameTd.appendTo(tr)
        const descrTd = $('<td/>')
        let dTxt = ''
        if (lv.description) {
          dTxt = lv.description.substring(0, 120)
        }
        descrTd.text(dTxt)
        descrTd.appendTo(tr)
        const locTd = $('<td/>', { style: 'max-width:100px' })
        locTd.text(lv.location_name)
        locTd.appendTo(tr)
        const langTd = $('<td/>')
        langTd.text(lv.language)
        langTd.appendTo(tr)
        const valTd = $('<td/>')
        if (lv.value) {
          valTd.text(lv.value.substring(0, 60))
          valTd.attr('title', lv.value.substring(0, 400))
        }
        valTd.appendTo(tr)
        const valFromTd = $('<td/>')
        valFromTd.text(lv.value_from)
        valFromTd.appendTo(tr)
        // use targetOption to determine whether or not to make the link open in a new tab or this tab
        const targetOption = lv.manage_in_single_tab ? {} : { target: '_blank' }
        const linksTd = $('<td/>')
        if (lv.show_url) {
          const t1 = lv.show_link_title
          const l1 = $('<a/>', _.merge({ href: lv.show_url, style: 'margin-right:10px;', title: t1 }, targetOption))
          l1.append($('<span/>', { class: 'glyphicon glyphicon-eye-open' }))
          l1.appendTo(linksTd)
        }
        if (lv.edit_url) {
          const editConfirmOption = {}
          if (lv.warn_on_editing) {
            editConfirmOption['data-confirm'] = lv.warn_on_editing
          }
          const t2 = lv.edit_link_title
          const l2 = $('<a/>', _.merge({ href: lv.edit_url, style: 'margin-right:10px;', title: t2 }, targetOption, editConfirmOption))
          l2.append($('<span/>', { class: 'glyphicon glyphicon-pencil' }))
          l2.appendTo(linksTd)
        }
        if (lv.create_url) {
          const t3 = lv.create_link_title
          const l3 = $('<a/>', _.merge({ href: lv.create_url, style: 'margin-right:10px;', title: t3 }, targetOption))
          l3.append($('<span/>', { class: 'glyphicon glyphicon-pencil' }))
          l3.appendTo(linksTd)
        }
        if (lv.overwrite_url) {
          const overwriteConfirmOption = {}
          if (lv.warn_on_overwriting) {
            overwriteConfirmOption['data-confirm'] = lv.warn_on_overwriting
          }
          const t4 = helperDiv.data('overwriteTitle')
          const l4 = $('<a/>', _.merge({ href: lv.overwrite_url, title: t4, style: 'margin-right:10px;' }, targetOption, overwriteConfirmOption))
          l4.append($('<span/>', { class: 'glyphicon glyphicon-refresh' }))
          l4.appendTo(linksTd)
        }
        if (lv.destroy_url) {
          const destroyConfirmOption = { 'data-confirm': lv.warn_on_destroy }
          const t6 = lv.destroy_link_title
          const l6 = $('<a/>', _.merge({ href: lv.destroy_url, 'data-method': 'delete', title: t6, style: 'margin-right:10px;' }, targetOption, destroyConfirmOption))
          l6.append($('<span/>', { class: 'glyphicon glyphicon-trash' }))
          l6.appendTo(linksTd)
        }
        if (lv.show_overwrite_disallowed) {
          const t5 = helperDiv.data('overwriteDisallowedTitle')
          const el = $('<span/>', { class: 'glyphicon glyphicon-lock', title: t5, style: 'margin-right:10px;' })
          el.appendTo(linksTd)
        }
        linksTd.appendTo(tr)

        tr.appendTo(lvTable)
      }
    }
  } // if($('#loc-vars-helper').length > 0){
}
