import { readCookie, createCookie } from 'helpers/cookies'

export default function() {
  initToggleInactiveApps()
  // When multiple people (potential duplicates) button to show/hide apps
  $('.show-hide-persons-appl').on('click', function() {
    $(this).closest('table').next('.a-persons-applications').toggle()
  })
}

function initToggleInactiveApps() {
  // initial state of the utton
  const inactiveAppsVisible = readCookie('calm_inactiveAppsVisible') || 'false'
  toggleInactiveAppButtonState(inactiveAppsVisible == 'false')

  $('#show-hide-inactive-apps').on('click', () => {
    const currentState = $('tr.inactive-application:visible').length > 0
    toggleInactiveAppButtonState(currentState)
    createCookie('calm_inactiveAppsVisible', !currentState, 30)
  })
}

function toggleInactiveAppButtonState(hide) {
  const $toggleInactiveAppsBtn = $('#show-hide-inactive-apps')
  const $inactiveApplications = $('tr.inactive-application')

  if (hide) {
    $inactiveApplications.hide()
    // also hide any open details about the application
    $inactiveApplications.next('.toggle-one-appl-detail').find('.one-app-container').hide()
    $toggleInactiveAppsBtn.text(`${$toggleInactiveAppsBtn.data('showMsg')} (${$inactiveApplications.length})`)
    $toggleInactiveAppsBtn.removeClass('btn-default').addClass('btn-primary')
    $toggleInactiveAppsBtn.data('inactiveAppsVisible', false)
  } else {
    $inactiveApplications.show()
    $toggleInactiveAppsBtn.text(`${$toggleInactiveAppsBtn.data('hideMsg')} (${$inactiveApplications.length})`)
    $toggleInactiveAppsBtn.removeClass('btn-primary').addClass('btn-default')
    $toggleInactiveAppsBtn.data('inactiveAppsVisible', true)
  }
}
