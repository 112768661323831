<template>
  <div>
    <!-- Search Bar -->
    <div class="input-group search-group">
      <span class="input-group-addon search-icon">
        <i class="fa fa-search" />
      </span>
      <input
        ref="searchInput"
        v-model="search"
        class="form-control"
        type="text"
        :placeholder="$t('lookups.letters.search_placeholder')"
      >
      <select
        v-model="scope"
        class="input-group-addon form-control"
        @change="fetchTemplates"
      >
        <template v-if="forAt == 'true'">
          <option value="at_top_choices">
            {{ $t('lookups.letters.at_top_choices') }}
          </option>
        </template>
        <template v-else>
          <option value="state_templates">
            {{ $t('lookups.actions.letter_templates_for_current_state',
                  {conf_state: courseAppStateName}) }}
          </option>
          <option value="automatic_templates">
            {{ $t('lookups.actions.automatic_letter_templates') }}
          </option>
        </template>
        <option value="manual_templates">
          {{ $t('lookups.actions.all_available_letter_templates') }}
        </option>
      </select>
    </div>

    <div v-show="isFetching" class="calm-spinner-page-placeholder">
      <div class="calm-spinner" />
    </div>

    <!-- Letter table -->
    <AvailableLetterTemplatesTable
      v-show="!isFetching"
      :letter-templates="filteredLetterTemplates"
    />
    <div v-show="filteredLetterTemplates.length == 0 && !isFetching" class="help-block">
      No Template Found
    </div>
  </div>
</template>

<script>
import AvailableLetterTemplatesTable from 'components/letters/AvailableLetterTemplatesTable'
import { formatForSearch } from 'helpers/string_helper'

export default {
  components: { AvailableLetterTemplatesTable },
  props: {
    appId: {
      type: [String, Number],
      required: true
    },
    sms: {
      type: Object,
      default: () => ({})
    },
    forAt: {
      type: String,
      default: 'false'
    }
  },
  data() {
    return {
      templates: {
        at_top_choices: [],
        state_templates: [],
        automatic_templates: [],
        manual_templates: []
      },
      search: '',
      scope: 'state_templates',
      isFetching: false,
      courseAppStateName: ''
    }
  },
  mounted() {
    this.search = ''
    this.scope = this.forAt == 'true' ? 'at_top_choices' : 'state_templates'
    if (this.letterTemplates.length == 0) this.fetchTemplates()
    this.$refs.searchInput.focus()
    this.courseAppStateName = this.$root.course_application.confirmation_state_description
  },
  computed: {
    letterTemplates: {
      get() { return this.templates[this.scope] },
      set(newValue) { this.templates[this.scope] = newValue }
    },
    filteredLetterTemplates() {
      if (this.search == '') return this.letterTemplates
      return this.letterTemplates.filter(
        (template) => formatForSearch(template.name + template.description)
          .includes(formatForSearch(this.search))
      )
    }
  },
  methods: {
    fetchTemplates() {
      if (this.letterTemplates.length > 0) return // Already fetched
      this.isFetching = true
      $.getJSON(this.$routes.available_letter_templates_path(this.appId), {
        scope: this.scope,
        sms: this.sms
      }, (data) => {
        this.isFetching = false
        this.letterTemplates = data.letter_templates
        this.$refs.searchInput.focus()
      })
    },
    onStateChanged(newState) {
      // reset so they will be fetch again
      this.templates.state_templates = []
      this.courseAppStateName = newState.name
    }
  }
}
</script>

<style scoped lang="scss">
  .search-group.input-group {
    display: flex;
    margin: 10px 0 25px 0;

    & > * {
      height: 36px;
    }
    .search-icon {
      display: flex;
      width: 40px;
      align-items: center;
    }
    select { width: 320px; }
  }
</style>
