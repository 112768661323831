<template>
  <div class="course-applications-container">
    <CalmDataTable
      id="course-app-datatable"
      ref="table"
      v-model:contextMenuSelection="selectedApp"
      route="event_course_applications_index_datatable_path"
      :url-params="urlParams"
      :columns-toggle="!isDanaProfile"
      :clear-all-filters-btn="true"
      filter-display="row"
      :scrollable="true"
      scroll-height="flex"
      :row-hover="true"
      :paginator="true"
      :fields-to-hide="fieldsToHide"
      :transform-data="transformData"
      :context-menu="true"
      @filter="unhighlightQuotaTreeNodeIfNeeded"
      @row-clicked="redirectToApp"
      @row-contextmenu="$refs.cm.show($event.originalEvent)"
      @cell-after-edit="onCellAfterEdit"
    >
      <!-- :resizableColumns="true" columnResizeMode="expand" -->
      <template #header>
        <SelectButton
          v-if="!isDanaProfile"
          v-model="activeState"
          class="me-2 d-inline"
          :options="statesTabs"
        >
          <template #option="{ option }">
            {{ $t("tabs.course_application.index."
              + (option == 'enrolled_reserved' ? 'enrolled' : option)) }}
          </template>
        </SelectButton>
        <slot name="header" :state="activeState" :reorderUrl="reorderUrl" />
      </template>
    </CalmDataTable>
    <!-- Context menu -->
    <ContextMenu ref="cm" :model="contextMenuModel" />
  </div>
</template>

<script>
import CalmDataTable from 'components/datatable/CalmDatatable'
import ContextMenu from 'primevue/contextmenu'
import SelectButton from 'primevue/selectbutton'
import { transformStringWithNumbersForSorting } from 'helpers/string_helper'

export default {
  components: { CalmDataTable, ContextMenu, SelectButton },
  props: { isDanaProfile: { type: Boolean, default: false } },
  data() {
    return {
      states: {},
      statesTabs: [],
      activeState: null,
      currentQuotaTreeNode: {},
      currentCategory: '',
      selectedApp: null
    }
  },
  mounted() {
    this.states = gon.confirmation_states
    this.statesTabs = Object.keys(gon.confirmation_states)
    if (this.isDanaProfile) {
      this.activeState = 'enrolled_reserved'
      this.currentCategory = 'all'
    } else {
      let { hash } = window.location
      if (!hash) hash = window.sessionStorage.getItem(this.storageKey) || ''
      hash = hash.slice(1)
      this.activeState = hash.split('@')[0] || 'enrolled_reserved'
      if (hash.includes('@')) {
        const [, category] = hash.split('@')
        this.currentCategory = category
      }
      if (window.quotaTree) window.quotaTree.bindOnClick(this.onQuotaTreeClick)
    }

    // Make the table take full available height
    setTimeout(() => {
      const height = $('.p-datatable').offset().top
      $('.p-datatable').css('max-height', `calc(100vh - ${height}px - .6rem)`)
    }, 0)
  },
  computed: {
    urlParams() {
      return { event_id: gon.event_id, state_filter: this.activeState }
    },
    reorderUrl() {
      return Routes.event_course_applications_reorder_path(
        gon.locale,
        gon.event_id,
        this.currentCategory || 'all'
      )
    },
    contextMenuModel() {
      if (!this.selectedApp) return []
      const internalUrl = this.internalUrlFor(this.selectedApp)
      return [{ label: 'Open', url: internalUrl, icon: 'pi pi-fw pi-folder-open' },
        {
          label: 'Open in new tab',
          url: internalUrl,
          icon: 'pi pi-fw pi-external-link',
          target: '_blank'
        }]
    },
    fieldsToHide() {
      const result = []
      if (this.activeState != 'on_wait_list') result.push('wait_list_rank')
      if (this.activeState != 'enrolled_reserved') result.push('room_id')
      return result
    },
    locationHash() {
      return this.currentCategory
        ? `#${this.activeState}@${this.currentCategory}` : `#${this.activeState}`
    },
    storageKey() {
      return 'course_app_index_hash'
    }
  },
  methods: {
    onQuotaTreeClick(node) {
      node = { ...node } // clone to avoid side effects
      node.gender = node.gen; delete node.gen
      node.conversation_locale = node.lang; delete node.lang
      this.currentCategory = node.category
      this.currentQuotaTreeNode = {}
      const fields = ['gender', 'old', 'sitting', 'full_time', 'conversation_locale']
      fields.forEach((field) => {
        if (node[field] == 'undefined') node[field] = undefined
        if (node[field]) this.currentQuotaTreeNode[field] = node[field]
        this.filter(field).value = node[field]
      })
    },
    unhighlightQuotaTreeNodeIfNeeded() {
      if (this.currentQuotaTreeNode.length == 0) return

      if (
        Object.entries(this.currentQuotaTreeNode).some(
          ([field, value]) => this.filter(field).value != value
        )
      ) {
        window.quotaTree.unhighlightNode()
        this.currentQuotaTreeNode = {}
        this.currentCategory = ''
      }
    },
    filter(field) {
      return this.$refs.table.filters[field]
    },
    internalUrlFor(app) {
      const visibleIds = this.$refs.table.$refs.table.processedData.map((i) => i.id).join(',')
      return this.$routes.edit_event_course_application_path(
        gon.event_id,
        app.id,
        { from_index: true, app_ids: visibleIds }
      )
    },
    redirectToApp(event) {
      const app = event.data
      const url = this.internalUrlFor(app)
      if (event.originalEvent.ctrlKey) window.open(url, '_blank')
      else Turbo.visit(url)
    },
    transformData(items) {
      return items.map((item) => {
        const newItem = {
          ...item,
          room_name_for_sorting: transformStringWithNumbersForSorting(item.room_name)
        }
        return newItem
      })
    },
    onCellAfterEdit(event) {
      const { data, field, newValue, oldValue } = event
      if (field == 'room_id') {
        const newRoom = this.$root.rooms.find((r) => r.id == newValue) || {}
        const oldRoom = this.$root.rooms.find((r) => r.id == oldValue) || {}
        data.room_name = newRoom.name // the filter is made on room_name attribute, not on room id
        data.room_name_for_sorting = transformStringWithNumbersForSorting(data.room_name)
        if (oldRoom.own_accommodation != newRoom.own_accommodation && window.quotaTree) {
          window.quotaTree.retrieveQuotaTreeAjax()
        }
      }
    }
  },
  watch: {
    locationHash(newValue) {
      if (this.activeState) window.sessionStorage.setItem(this.storageKey, newValue)
      document.location.hash = newValue
    }
  }
}
</script>

<style lang="scss">
  @media (max-width: 1100px) {
    #course-app-datatable {
      max-height: none !important;
    }
  }
  .course-app-section, .course-apps-with-dana-profile {
    .p-contextmenu {
      margin-top: -70px;
    }
    :deep(.p-paginator) {
      margin-bottom: -.5rem;
    }
    > .panel:not(#scratchpad-display) {
      background-color: transparent;
      margin: 0;
      border: none;
      box-shadow: none;
      > .panel-body { padding: 0; }
    }
    .p-buttonset .p-button {
      min-width: 170px;
      // padding: 6px 25px;
      justify-content: center;
    }
  }
  #course-app-datatable td {
    small {
      margin-left: 3px;
      font-size: .85rem;
      background-color: var(--bluegray-50);
      padding: 2px 4px;
      border-radius: 2px;
      i {
        font-size: .75rem;
        font-style: normal;
        margin-right: 2px;
      }
    }
  }

</style>
