$(document).on('calm:turbo:load', () => {
  // auto load for subscription statistics page, but in person context it's loaded by webpack
  if ($('#publication-subscriptions-datatable').length > 0 && $('#people-show-edit').length == 0) {
    initDatatable()
  }
})

export default function initDatatable() {
  modit('calmApp.publicationSubscriptionIndex', ['calmApp.dtSettings', 'calmApp.ajaxUtils', 'gon'], (dtSettings, calmAjaxUtils, gon) => {
    const locationIdSelect = $('select#location_id')
    const tableContainer = $('#publication-subscriptions-datatable')
    const savedPageLength = tableContainer.data('length') || 25
    tableContainer.removeAttr('data-length').removeData('length') // dataTables needs this removed

    locationIdSelect.change(function() {
      Turbo.visit(`${gon.index_url}?location_id=${$(this).val()}`)
    })

    const columns = [
      { orderable: true, className: 'allow-word-wrap', width: '10%' }, // type
      { orderable: true, className: 'allow-word-wrap', width: '10%' }, // publication type
      { orderable: true, className: 'allow-word-wrap', width: '10%' }, // location
      { orderable: true, className: 'allow-word-wrap', width: '10%' }, // created_at
      { orderable: true, className: 'allow-word-wrap', width: '10%' }, // operator
      { orderable: true, className: 'allow-word-wrap', width: '10%' }, // operator action
      { orderable: false, className: 'allow-word-wrap', width: '10%' } // person
    ]

    const defaultSettings = _.merge({}, dtSettings.getCalmDatatableSettings(), dtSettings.getAjaxDTSettings())
    const datatableSettings = _.merge(
      defaultSettings,
      {
        pageLength: parseInt(savedPageLength),
        language: dtSettings.getDTLangTranslations(),
        order: [[3, 'desc']],
        stateSave: false,
        ajax: {
          url: tableContainer.data('source'),
          data(data) {
            data.location_id = locationIdSelect.val()
            data.search = {} // add search params
            $('.rep-filter > input, .rep-filter > select').each(function() {
              if ($(this).val()) data.search[$(this).attr('name')] = $(this).val()
            })
            return data
          },
          error(request, status, error) {
            calmAjaxUtils.handleAjaxError(request, status, error, 'There was an error retrieving publications.')
          }
        },
        searching: false,
        columns,
        responsive: { breakpoints: dtSettings.getResponsiveBreakpoints() }
      }
    )
    const oTable = tableContainer.DataTable(datatableSettings)

    // bind the location select to search for loc vars
    $('.rep-filter input, .rep-filter select').change(() => { oTable.draw() })
  }) // modit('calmApp.publicationIndex'
}
