export default function() {
  if ($('#day-0').length > 0) {
    // SPECIAL SEAT
    $('#course_application_special_seat').change(function() {
      if ($(this).val() == 'chair' || $(this).val() == 'wall') {
        $('#special_seat_position_id').show()
      } else {
        $('#special_seat_position_id').hide()
      }
    })
  }
}
