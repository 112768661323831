export default {
  el: '#note-container',
  mounted() {
    $('#note_letter_tag').change(function() {
      $(this).toggleClass('letter-tag-active', $(this).val())
    })
    $('.resolve-tags-container .btn').click(function() {
      $(this).toggleClass('btn-success btn-success-light')
    })
  }
}
