import LettersTable from 'components/letters/LettersTable'

export default {
  components: { LettersTable },
  mounted() {
    this.$refs.lettersTable.onShow()
  },
  methods: {
    updateLettersDatatable(openLastAdded = false) {
      if (this.$refs.lettersTable) this.$refs.lettersTable.fetchLetters(openLastAdded)
    }
  }
}
