import 'select2'

export default {
  el: '#new-event-message',
  data: { defaultResults: [] },
  mounted() {
    const $select = $('#event_message_recipient_ids')
    this.defaultResults = $select.data('default')

    $select.select2({
      minimumInputLength: 0, // zero so processResults is called and we cna display default data
      ajax: {
        url: $select.data('url'),
        dataType: 'json',
        processResults: (data, page) => {
          // When openning without search, adds default values (registrars, Ats...)
          if (data.results.length == 0 && !$('.select2-search__field').val()) data.results = this.defaultResults
          return data
        },
        cache: true
      },
      placeholder: this.$t('lookups.event_messages.recipients'),
      multiple: true,
      escapeMarkup(m) { return m }, // enable HTML in options
      width: '100%'
    })
    // Fix strange bug placeholder behing cropped
    setTimeout(() => {
      if (!$select.val()) $('.select2-search__field').width($('.select2-selection__rendered').innerWidth())
    }, 100)
  }
}
