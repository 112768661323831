<template>
  <tr
    class="letter-header"
    :class="{inbox: myInbox, 'other-inbox': notMyInbox, 'limited-view': letter.limited_view}"
    @click="toggleActiveLetter"
  >
    <td
      v-if="!readOnly && courseAppContext"
      class="with-icon-btn"
      :title="letter.actions.toggle_important.label"
      @click.prevent.stop="$emit('toggle-important', letter)"
    >
      <span class="fa-star icon-btn" :class="letter.important ? 'fas active' : 'far'" />
    </td>
    <td
      v-if="!readOnly"
      class="with-icon-btn"
      :title="inboxTitle"
      @click.prevent.stop="toggleInbox"
    >
      <span
        v-if="canToggleInbox"
        class="fas fa-inbox icon-btn"
        :class="letter.in_inbox ? 'active' : ''"
      />
    </td>
    <td class="name" :title="letter.template_name.length > 30 ? letter.template_name : ''">
      {{ letter.template_name.length > 30
        ? letter.template_name.slice(0,30) + '..' : letter.template_name }}

      <span v-if="myInbox" class="label inbox-reason" :class="letter.inbox_reason_label_class">
        {{ $t("lookups.inbox_entry.category." + letter.inbox_reason) }}
      </span>
      <span v-if="notMyInbox" class="label label-default-light">
        {{ $t('lookups.inbox.not_my_inbox.' + (letter.recipient_target || 'personal')) }}
      </span>
      <i v-if="letter.attachments.length > 0" class="fa fa-paperclip attachment-icon" />
    </td>
    <td class="operator hidden-xs">
      {{ letter.operator }}
    </td>
    <td v-if="courseAppContext && $can('manage_letter_tags', 'messages')" class="letter-tags">
      <div
        v-for="tag in letter.letter_tags.deactivated"
        :key="tag"
        class="letter-tag deactivated-tag"
        :title="$t('lookups.letter_tag.click_to_unresolve')"
        @click.stop="$parent.toggleLetterTag(letter, 'deactivated', tag)"
      >
        {{ tag }}
      </div>
      <template v-if="letter.letter_tags.activated">
        <div v-if="letter.letter_tags.pending" class="letter-tag activated-tag">
          {{ letter.letter_tags.activated }}
        </div>
        <div v-if="!letter.letter_tags.pending" class="letter-tag associated-tag">
          {{ letter.letter_tags.activated }}
        </div>
      </template>
      <div
        v-for="tag in letter.letter_tags.associated"
        :key="tag"
        class="letter-tag associated-tag"
        :title="$t('lookups.letter_tag.click_to_unassociate')"
        @click.stop="$parent.toggleLetterTag(letter, 'associated', tag)"
      >
        {{ tag }}
      </div>
    </td>
    <td class="status hidden-xs">
      {{ letter.display_status }}
    </td>
    <td class="date" :title="$l(letter.sent_at, 'time')">
      {{ $l(letter.sent_at, 'dateWithoutYear') }}
      <span v-if="letter.day_to_course" class="day-to-course hidden-xs">
        ({{ letter.day_to_course }})
      </span>
    </td>
    <BaseModal
      ref="confirmTaskDialog"
      :title="$t('formtastic.actions.remove_transmission_from_inbox_short')"
    >
      <LetterTaskConfirmation
        v-if="letter.task_reminder"
        :letter="letter"
        @update:letter="updateLetter"
        @close="$refs.confirmTaskDialog.hide()"
      />
    </BaseModal>
  </tr>
</template>

<script>
import LetterTaskConfirmation from 'components/letters/LetterTaskConfirmation'

export default {
  components: { LetterTaskConfirmation },
  props: {
    letter: {
      type: Object,
      required: true
    },
    activeLetterId: {
      type: Number,
      default: null
    },
    readOnly: Boolean,
    courseAppContext: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:activeLetterId', 'update:letter', 'toggle-important'],
  data() {
    return { showTaskConfirm: false }
  },
  computed: {
    myInbox() { return !this.readOnly && this.letter.in_inbox && this.letter.is_for_me },
    notMyInbox() { return this.letter.in_inbox && !this.letter.is_for_me },
    canToggleInbox() {
      return this.letter.is_for_me && this.letter.status_sent && this.letter.inbox_reason
    },
    taskReminder() { return this.letter.task_reminder },
    inboxTitle() {
      if (!this.canToggleInbox) return ''
      return this.letter.in_inbox
        ? this.$t('formtastic.actions.remove_transmission_from_inbox_short')
        : this.$t('formtastic.actions.add_back_to_inbox')
    }
  },
  methods: {
    toggleActiveLetter() {
      this.$emit(
        'update:activeLetterId',
        (this.letter.id == this.activeLetterId) ? null : this.letter.id
      )
    },
    toggleInbox() {
      if (!this.canToggleInbox) return
      if (this.taskReminder && this.letter.in_inbox) {
        this.$refs.confirmTaskDialog.show()
        return
      }
      const url = this.$routes.comm_toggle_inbox_transmission_path(this.letter.id)
      $.ajax(url, {
        type: 'PATCH',
        dataType: 'json',
        success: (data) => {
          this.$emit('update:letter', data.letter)
        }
      })
    },
    updateLetter(newLetter) {
      // just to pass the event to the tabel from the child LetterTaskConfirmation component
      this.$emit('update:letter', newLetter)
    }
  }
}
</script>

<style scoped lang="scss">
  .day-to-course {
    font-size: .8em;
    margin-left: 6px;
  }
  .name .label { margin-left: 5px; }

  tr.inbox td {
    font-weight: bold;
    background-color: #e8eef3;
    color: var(--bs-primary);
  }
  tr.limited-view td {
    color: #9a9a9a;
    .label, .letter-tag { opacity: .5; }
  }
  .label.important {
    margin-left: 0;
    color: var(--bs-primary);
  }
  .label.not-my-inbox {
    color: #616060;
  }
  td.with-icon-btn {
    width: 5px;
    .icon-btn {
      font-size: 15px;
      position: relative;
      opacity: .3;
      &.active { opacity: .7; }
    }
    &:hover {
      .icon-btn { opacity: 1; }
      .icon-btn:after {
        content: "";
        border-radius: 50%;
        position: absolute;
        width: 25px;
        height: 25px;
        left: -4px;
        top: -4px;
        background-color:rgba(103, 103, 103, 0.2);
      }
    }
  }
  td.letter-tags {
    padding: 0 8px;
  }
  .attachment-icon {
    margin-left: 8px;
    opacity: .7;
    font-size: .85em;
  }
</style>
