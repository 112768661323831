<template>
  <span class="confirm-button text-start">
    <div class="btn" :class="btnClass" @click.prevent.stop="displayDialog = true">
      <slot />
    </div>

    <Dialog
      v-model:visible="displayDialog"
      header="Confirmation"
      position="top"
      :modal="true"
      :container-style="{width: '400px'}"
    >
      <p class="mb-0">{{ confirmMessage }}</p>
      <template #footer="">
        <div class="btn btn-default me-2" @click.prevent.stop="displayDialog = false">
          {{ $t('comm.lookups.general.no') }}
        </div>
        <div class="btn" :class="'btn-' + type" @click.prevent.stop="onConfirmed()">
          {{ $t('comm.lookups.general.yes') }}
        </div>
      </template>
    </Dialog>

    <!-- Use data-turbo false to work with VIS #6260 -->
    <form v-if="href" class="d-none" method="post" :action="href" data-turbo="false">
      <!-- _method is trick so rails will convert the post method into a GET/POST/DELETE -->
      <input name="_method" :value="method" type="hidden">
      <input
        v-if="csrfParam() && csrfToken()"
        :name="csrfParam()"
        :value="csrfToken()"
        type="hidden"
      >
      <input ref="submitBtn" type="submit">
    </form>
  </span>
</template>

<script>
import Dialog from 'primevue/dialog'

export default {
  components: { Dialog },
  props: {
    message: { type: String, default: null },
    type: { type: String, default: 'primary' },
    method: { type: String, default: 'get' },
    btnClass: { type: String, default: '' },
    href: { type: String, default: '' }
  },
  emits: ['confirmed'],
  data() {
    return { displayDialog: false }
  },
  computed: {
    confirmMessage() {
      return this.message || this.$t('comm.messages.are_you_sure')
    }
  },
  methods: {
    onConfirmed() {
      this.displayDialog = false
      this.$emit('confirmed')
      if (this.$refs.submitBtn) this.$refs.submitBtn.click()
    },
    csrfToken() {
      const meta = document.querySelector('meta[name=csrf-token]')
      return meta ? meta.content : null
    },
    csrfParam() {
      const meta = document.querySelector('meta[name=csrf-param]')
      return meta ? meta.content : null
    },
    close() {
      this.displayDialog = false // using a ref you can call this method on your confirm action see confirmUnsubscribe in engines/mvip/app/assets/new_javascripts/mvip/pages/mvip_publication_subscriptions/get_confirm_subscribe.js
    }
  }
}
</script>
