<template>
  <div class="row mb-2">
    <!-- Person -->
    <div class="col-md-4">
      <calm-select
        v-model="row.personId"
        :options="people"
        label="text"
        track-by="id"
        placeholder="Search by Name or Email"
        :loading="isLoading"
        :internal-search="false"
        @search-change="searchPerson"
      >
        <template #singleLabel="{ option }">
          <strong>{{ option.text }}</strong>
        </template>
      </calm-select>
    </div>

    <template v-if="row.personId">
      <!-- Amount -->
      <div class="col-md-1" :class="{ 'has-error': !row.amount || row.amount <= 0 }">
        <input ref="amountInput" v-model="row.amount" class="form-control" type="number">
      </div>
      <!-- Currency -->
      <div class="col-md-1" :class="{ 'has-error': !row.currency }">
        <calm-select v-model="row.currency" :options="currencyList" />
      </div>
      <!-- Category -->
      <div class="col-md-2" :class="{ 'has-error': !row.category }">
        <calm-select v-model="row.category" :options="categoryList" />
      </div>
      <!-- Transaction Type -->
      <div class="col-md-2" :class="{ 'has-error': !row.transactionType }">
        <calm-select v-model="row.transactionType" :options="transactionTypeList" />
      </div>
      <!-- Transaction Date -->
      <div
        class="col-md-2 d-flex align-items-center"
        :class="{ 'has-error': !row.transactionDate }"
      >
        <input v-model="row.transactionDate" class="form-control" type="date">
        <!-- Delete Button -->
        <button class="btn btn-link py-0" type="button" @click="$emit('rowDeleted')">
          <i class="fa fa-times text-danger fs-2" />
        </button>
      </div>
    </template>
  </div>
</template>

<script>

import CalmSelect from 'components/common/CalmSelect'
import { axios, handleAjaxError, debounce } from 'helpers/core/ajax-utils'

export default {
  components: { CalmSelect },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    transactionDate: {
      type: String,
      default: ''
    },
    transactionTypeList: {
      type: Array,
      default: () => []
    },
    categoryList: {
      type: Array,
      default: () => []
    },
    currencyList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['rowDeleted'],
  data() {
    return {
      row: {
        personId: undefined,
        category: undefined,
        currency: undefined,
        amount: 0,
        transactionType: undefined
      },
      people: [],
      isLoading: false,
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  },
  mounted() {
    this.row = this.modelValue
  },
  methods: {
    searchPerson: debounce(function(query) {
      this.isLoading = true
      axios.get(this.$routes.person_search_path({ format: 'json' }), {
        params: {
          term: query,
          limit: 10
        }
      }).then((result) => {
        this.people = result.data.results
        this.isLoading = false
      }).catch((error) => handleAjaxError(error, this))
    })
  },
  watch: {
    'row.personId': async function() {
      await this.$nextTick()
      this.$refs.amountInput.focus()
    }
  }
}
</script>
<style lang='scss' scoped>
.some-pad-bottom{
  padding-bottom: 10px;
}
.has-error {
  .form-control {
    background-color: var(--bs-danger);
    &input { color: white; }
  }
}
</style>
