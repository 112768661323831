<template>
  <span>
    <a :class="linkClass" @click="openModal">{{ linkText }}</a>
    <BaseModal ref="modal" :title="modalTitle ? modalTitle : linkText">
      <slot />
    </BaseModal>
  </span>
</template>

<script>
import BaseModal from 'components/common/BaseModal'

export default {
  components: { BaseModal },
  props: {
    linkText: {
      type: String,
      required: true
    },
    linkClass: {
      type: String,
      required: false,
      default: ''
    },
    modalTitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    openModal() {
      // If a minimized modal exist, we open it instead of loading the new modal refs #4875
      if (document.querySelector('.modal.minimized')) {
        document.querySelector('.modal.minimized .maximize-button').click()
      } else {
        this.$refs.modal.show()
      }
    }
  }
}
</script>
