import initAllJsAfterContentLoaded from 'helpers/core/init-all-js-after-content-loaded'

export default function() {
  if (!gon.dana_datatable) return

  const tableContainer = $('#dana-datatable')
  const defaultSettings = _.merge({}, calmApp.dtSettings.getCalmDatatableSettings())
  // the settings required specifically for the conversations table
  let columns = [
    { orderable: true, className: 'allow-word-wrap', width: '9%', type: 'calm-date-in-data-attr' }// 0-transaction date. calm-date-in-data-attr refs #3094.
  ]
  let colOffset = 0
  let colOffsetPost = 0
  if (!gon.is_course_context_dana) {
    columns.push({ orderable: false, className: 'allow-word-wrap', width: '6%' })// location name (pos. 1);
    columns.push({ orderable: false, className: 'allow-word-wrap', width: '8%' })// event name (pos. 2);
    colOffset = 2
    colOffsetPost = 2
  }
  columns = columns.concat([
    { orderable: false, className: 'allow-word-wrap', width: '6%' }, // 1-user
    { orderable: false, className: 'allow-word-wrap', width: '6%' }, // 2-receipt id
    { orderable: false, className: 'allow-word-wrap', width: '6%' }, // 3-category
    { orderable: false, className: 'allow-word-wrap', width: '6%' }, // 4-transaction type
    { orderable: false, className: 'allow-word-wrap', width: '6%' }, // 5-status
    { orderable: false, className: 'allow-word-wrap', width: '8%' }// 6-observation
  ])
  if (gon.tax_question_active) {
    columns.push({ orderable: false, className: 'allow-word-wrap', width: '6%' })// 7-tax deductible
    colOffsetPost += 1
  }
  columns = columns.concat([
    { orderable: false, className: 'allow-word-wrap', width: '5%' }, // 8-currency
    { orderable: false, className: 'allow-word-wrap', width: '6%' }, // 9-donation amount
    { orderable: false, className: 'allow-word-wrap', width: '4%' } // 10-actions)
  ])
  const danaDataTableSettings = _.merge(
    defaultSettings,
    {
      paging: false,
      stateSave: false,
      // 'responsive': true,
      responsive: { breakpoints: calmApp.dtSettings.getResponsiveBreakpoints() },
      searching: false,
      info: false, // turns off the paging information bottom left (e.g. 1of 10 entries filtered from.. etc)
      // 'pageLength': parseInt(userCustomSearch.length),
      // 'displayStart': parseInt(userCustomSearch.start),
      // 'order': [1, 'asc'],
      data: gon.dana_datatable.data,
      columns,
      sDom: 'C<"clear">lfrtip',
      oColVis: {
        bRestore: true,
        align: 'right',
        exclude: [0, 5 + colOffset, 7 + colOffsetPost, 8 + colOffsetPost, 9 + colOffsetPost], // exclude date, status, currency, amount, actions
        buttonText: '',
        fnStateChange(iColumn, visible) {
          let preference = {}
          switch (iColumn) {
            case 1 + colOffset:
              preference = { dana_userColVis: visible }
              break
            case 2 + colOffset:
              preference = { dana_receipt_idColVis: visible }
              break
            case 3 + colOffset:
              preference = { dana_categoryColVis: visible }
              break
            case 4 + colOffset:
              preference = { dana_trans_typeColVis: visible }
              break
            case 6 + colOffset:
              preference = { dana_observationsColVis: visible }
              break
          }
          if (gon.tax_question_active && iColumn == 7 + colOffset) {
            preference = { dana_taxColVis: visible }
          }
          calmApp.userPrefUtil.updateUserPreferences({ danaIndex: preference })
        }
      },
      rowCallback() {
        initAllJsAfterContentLoaded()
      }
    }
  )

  tableContainer.dataTable(danaDataTableSettings)
}
