<template>
  <div class="character-count">
    <span class="page-count">{{ $t('lookups.sms.sms_charged_count') }}</span>
    <span class="page-count value fw-bold" v-text="smsChargedCount" />
    <template v-if="nonGsmChars.length > 0">
      <span class="non-gsm-chars">
        {{ $t('lookups.sms.foreign_chars_warning', {large_size: 160, small_size: 70}) }}
        <span class="fw-bold ms-1">{{ ' = ' + nonGsmChars.join(' ') }}</span>
      </span>
    </template>
  </div>
</template>

<script>
import { SegmentedMessage } from 'sms-segments-calculator/dist/index'

export default {
  props: {
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    segmentedMessage() {
      return new SegmentedMessage(this.content || '')
    },
    smsChargedCount() {
      return (this.content && this.content.length > 0) ? this.segmentedMessage.segmentsCount : 0
    },
    nonGsmChars() {
      return this.segmentedMessage.getNonGsmCharacters().filter((v, i, a) => a.indexOf(v) === i)
    }
  }
}
</script>

<style lang='scss' scoped>
.character-count {
  margin: 0;
  padding: 5px .8rem;
  background-color: #f3f2f4;
  color: #565656;
  border: 1px solid #cccccc;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  font-size: 13px;
}
span:not(:first-child) {
  margin-left: 8px;
}
.non-gsm-chars {
  margin-left: 1rem;
  color: #ca650f;
}
</style>
