import SegmentsRenderer from 'components/letters/SegmentsRenderer'

export default {
  el: '#new-external',
  components: { SegmentsRenderer },
  data: {
    selectedTab: 'message',
    content: undefined,
    context_message: undefined,
    additional_question: undefined,
    custom_form_sections: undefined
  },
  mounted() {
    $('.switch-toggle input[checked]').trigger('click') // fix switch when loaded by ajax
  }
}
