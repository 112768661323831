import 'bootstrap/js/modal'

export default {
  // TODO VIS: this is no longer handled when signin in with vis
  mounted() {
    // SAFARI warning pops up only on login screen for reg app refs #4540
    if ($('#mvip-login-screen').length === 0) { // actually this was not being run on the mvip login screen however, in the future if we include Vue into the mvip, thn it might be and then we do not want to tell students not to use Safari
      const isSafariBrowser = navigator.vendor && navigator.vendor.indexOf('Apple') > -1
        && navigator.userAgent
        && navigator.userAgent.indexOf('CriOS') == -1
        && navigator.userAgent.indexOf('FxiOS') == -1
      if (isSafariBrowser) {
        calmAlertBootstrap({
          headerText: 'Problem with Safari',
          messageText: this.$t('errors.messages.browser_safari'),
          alertType: 'danger'
        })
      }
    }
  }
}
