<template>
  <DataTable
    :value="courseApplications"
    :auto-layout="true"
    :loading="loading"
    @row-reorder="$emit('reorder', $event.value)"
  >
    <Column
      body-class="reorder-column p-datatable-reorderablerow-handle"
      :row-reorder="true"
    />
    <Column
      body-class="p-datatable-reorderablerow-handle"
      field="sequential_wait_list_rank"
      :header="$t('activerecord.attributes.course_application.short_table_headers.wait_list_rank')"
    />
    <Column
      body-class="p-datatable-reorderablerow-handle"
      field="display_id"
      :header="$t('activerecord.attributes.course_application.display_id')"
    >
      <template #body="slotProps">
        <a
          :href="$routes.edit_event_course_application_path(courseId, slotProps.data.id)"
          target="_blank"
        >
          {{ slotProps.data.display_id }}
        </a>
      </template>
    </Column>
    <Column
      body-class="p-datatable-reorderablerow-handle"
      field="person.family_name"
      :header="$t('activerecord.attributes.course_application.applicant_family_name')"
    />
    <Column
      body-class="p-datatable-reorderablerow-handle"
      field="person.given_name"
      :header="$t('activerecord.attributes.course_application.applicant_given_name')"
    />
    <Column
      body-class="p-datatable-reorderablerow-handle"
      field="confirmation_state_display_name"
      :header="$t('lookups.course_application.confirmation_state_table_header')"
    />
    <Column
      body-class="p-datatable-reorderablerow-handle"
      field="person.priority_note"
      :header="$t('activerecord.attributes.person.priority_note_medium')"
    >
      <template #body="{data}">
        <span v-if="data.person.priority_note" class="note-alerts">
          <span class="note-alert">PRIO</span>
        </span>
        {{ data.person.priority_note }}
      </template>
    </Column>
    <Column
      body-class="p-datatable-reorderablerow-handle"
      field="ad_hoc"
      :header="$t('activerecord.attributes.course_application.ad_hoc')"
    />
    <template #empty>
      <slot name="empty" />
    </template>
  </DataTable>
</template>

<script>
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'

export default {
  name: 'CourseApplicationsReorderTable',
  components: {
    Column,
    DataTable
  },
  props: {
    courseId: {
      type: Number,
      required: true
    },
    courseApplications: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['reorder']
}
</script>

<style lang="scss">
.p-datatable .p-datatable-reorderablerow-handle {
  cursor: grab;
}
</style>
