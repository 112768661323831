export function initDatatableFilters() {
  if (!$.fn.dataTableExt) return
  // Custom Datatable filter Refs #3094
  // Use the value iside data-date to sort the column
  $.extend($.fn.dataTableExt.oSort, {
    'calm-date-in-data-attr-pre': function(a) {
      return $(a).data('date')
    }
  })
}

export function initCalmDatatables() {
  initClickableRows()

  // setTimeout to wait datatable are initialized
  setTimeout(() => {
    $('table.dataTable').each(function() {
      initCalmDatatable($(this))
    })

    // Hiding empty header and actionbar
    $('.dataTables_header, .dataTables_actionbar').each(function() {
      if (!$(this).html().replace(/\s/g, '')) $(this).hide()
    })
  }, 0)
}

function initCalmDatatable(table) {
  if (table.hasClass('initialized')) return
  table.addClass('initialized')

  let container = table.closest('.dataTables_wrapper')
  if (container.length == 0) container = table.parent() // When datatable js library is not used

  // HEADER POSITIONING
  let header = container.siblings('.dataTables_header')
  if (header.length == 0) header = container.parent().siblings('.dataTables_header')
  if (header.length == 0) {
    header = $('<div>', { class: 'dataTables_header' })
    header.insertBefore(container)
  }

  // SEARCH POSITIONING
  const search = container.find('.dataTables_filter input[type=search]').removeClass('input-sm')
  if (search.length) {
    const searchIcon = $('<div class="input-group-addon"><i class="glyphicon glyphicon-search"></i></div>')
    let searchContainer = header.find('.dataTables_search')
    if (searchContainer.length == 0) {
      searchContainer = $('<div>', { class: 'dataTables_search pull-right' })
      searchContainer.appendTo(header)
    }
    searchIcon.appendTo(searchContainer)
    search.appendTo(searchContainer)
  }

  // FOOTER POSITIONING
  const footer = $('<div>', { class: 'dataTables_footer' })
  footer.insertAfter(table)
  const perPageContainer = $('<div>', { class: 'dataTables_perPage' })
  const perPage = container.find('.dataTables_length select')
  const infos = container.find('.dataTables_info')
  const paginate = container.find('.dataTables_paginate')
  infos.appendTo(footer)
  paginate.appendTo(footer)
  perPageContainer.appendTo(footer)
  perPage.appendTo(perPageContainer)
  // PROCESSING
  const processing = container.find('.dataTables_processing').appendTo(container.find('table'))
  const headerHeight = container.find('thead').height()
  processing.css('top', headerHeight)
  processing.css('height', `calc(100% - ${headerHeight}px)`)

  // SCROLLING CONTAINER
  if (container.parent().hasClass('scrolling-container-for-dt')) {
    header.insertBefore(container.parent().siblings('.suwala-doubleScroll-scroll-wrapper'))
    footer.insertAfter(container.parent())
  }

  // Disable sorting when clicking on the input filter
  $('.rep-filter.sorting input, .rep-filter.sorting select').click((e) => {
    e.preventDefault()
    e.stopPropagation()
  })

  // COLVIS - get the colvisButton generated by the datatable and append it into the header
  const colVisButton = container.find('button.ColVis_Button')
  colVisButton.html("<i class='glyphicon glyphicon-cog'></i>").removeClass('ColVis_Button').addClass('colvis-btn')
  colVisButton.appendTo(header.find('.colvis'))

  table.on('draw.dt', () => {
    setTimeout(() => { window.dispatchEvent(new Event('initAllJsAfterContentLoaded')) }, 0)
  })
}

// Clickable rows is activated on all table, even if the table was not drawn using datatableJs
function initClickableRows() {
  $('table.table:not(.prevent-clickable)').on('click', 'td', function(event) {
    if ($(event.target).closest('td').hasClass('no-row-click')
    || $(event.target).closest('table').hasClass('prevent-clickable')) { // handles tables inside table
      return
    }
    // refs #3325 no need to click again if the link was directly clicked
    if ($(event.target).is('a') || $(event.target).parents('a').length > 0) {
      let meth = $(event.target).data('method')
      if (typeof (meth) === 'undefined') {
        meth = $(event.target).parent().data('method') // in case of icon links, we actually click on the span icon not the link, so method is in the parent refs #3772
      }
      if (meth == 'delete') return
      if (meth == 'post') return
      if (meth == 'put') return
      if (meth == 'patch') return
      const link = $(event.target).is('a') ? $(event.target) : $(event.target).parents('a').first()
      if (!link.attr('href')) return
      if (!link.hasClass('clicked') && !link.hasClass('prevent-row-click')) {
        const url = link.attr('href')
        if (event.ctrlKey) window.open(url, '_blank')
        else if (link.data('turbo') === false) window.location = url
        else Turbo.visit(url)
      }
      link.addClass('clicked') // prevent the link to be clicked multiple times
      setTimeout(() => { link.removeClass('clicked') }, 5000) // remove after 5 seconds so it can be used again
      event.preventDefault()
      event.stopPropagation()
      return
    }

    const $row = $(this).closest('tr')
    // cannot allow click on first column when expand icon appears
    // cannot allow click when column contains links such as action column
    // cannot allow click on more info rsl inserted row
    const isFirstColumn = $(this).html() == $row.find('td:visible').first().html()
    if (($(this).closest('table').hasClass('collapsed') && isFirstColumn)
        || (!isFirstColumn && $(this).find('a').length > 0)
        || $row.hasClass('more-info-rsl')) return

    // look first to the link with href attribute, otherwise trigger click on any link (like the show more button)
    let link = $row.find('a:not(.prevent-row-link):not(.clicked)[href]:not([v-remote-modal])')
    if (link.length) {
      $row.css('background-color', '#b0cce4').css('color', '#337ab7') // highlight
      link.addClass('clicked')
      const url = link.attr('href')
      event.stopPropagation() // this prevents nested datatables from registering twice, and so redirecting to the wrong url refs #4195
      if (event.ctrlKey) {
        window.open(url, '_blank')
      } else {
        Turbo.visit(url)
      }
    } else {
      link = $row.find('a:not(.prevent-row-link):not(.clicked)')
      link.addClass('clicked')
      link.trigger('click')
    }
  })
}
