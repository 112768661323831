export default function initReferralEntryForm() {
  function getLocationInput() {
    return $('#new_ref_entry_location_of_behaviour_id')
  }
  function getSpEntryInput() {
    return $('#is_special_entry')
  }

  if ($('#referral-entries-new, #people-datatable').length == 0) return

  $('#new_ref_entry_extend_life').on('change', function() {
    $('.extend-life-section').toggle($(this).prop('checked'))
  }).trigger('change')

  modit('calmApp.referralEntriesNew', ['gon'], (gon) => {
    const commentMaxLength = 2000
    const commentMinLength = 5
    const inputComment = $('#new_ref_entry_comment')
    const iframePersonForm = document.querySelector('iframe#person-details')

    initialize()

    function updateIframeHeight() {
      console.log('update iframe')
      if ($('.tab-pane#person-edit-form').hasClass('active')) iframePersonForm.style.height = `${iframePersonForm.contentWindow.document.body.scrollHeight}px`
    }

    function initialize() {
      const $locationInput = getLocationInput()

      $(iframePersonForm).load(updateIframeHeight)
      $('#iframe-tab-btn').click(updateIframeHeight)

      if (gon.edit_mode || gon.context_location_present) {
        const location = find_location_by_id($locationInput.val())
        handleLocationSelected(location)
      }
      const $countComment = $('#count-comment')
      if ($countComment.val()) {
        $countComment.html(`${inputComment.val().length} / ${commentMaxLength}`)
      }

      inputComment.keyup(() => {
        const textLength = inputComment.val().length
        $countComment.html(`${textLength} / ${commentMaxLength}`)
      })

      $('button.submit-form').click(function() {
        checkAndSendForm($(this).data('submit'))
      })

      getSpEntryInput().on('change', function() {
        const spEntrySelect = $(this)
        const warnSpEntry = $('#sp-list-warn')
        if (spEntrySelect.val() == 'true') {
          warnSpEntry.show()
        } else {
          warnSpEntry.hide()
        }
      })

      const buttonSetLocationToContext = $('#set-loc-to-context')
      buttonSetLocationToContext.click((e) => {
        const newLocId = buttonSetLocationToContext.data('locationId')
        $locationInput.val(newLocId).trigger('change')
        const location = find_location_by_id(parseInt(newLocId))
        $('.location_value').text(location.name)
      })

      // On location selected
      $locationInput.change(function(e) {
        $('.hide-on-place-select').hide()
        const locId = $(this).val()
        const location = find_location_by_id(locId)
        if (!location) { console.error('No location found !'); return }
        handleLocationSelected(location)
      })
    }

    function find_location_by_id($id) {
      return gon.locations.filter((loc) => (loc.id == $id))[0]
    }

    function handleLocationSelected(location) {
      const { ref_entry } = gon

      inputComment.val(ref_entry ? ref_entry.comment : '')
      $('#new_ref_entry_rlcode').val(ref_entry ? ref_entry.rlcode : '')

      $('.hide-before-location-selected').show()
      $('#select-location-help').hide()
    }

    function checkAndSendForm(submitOption) {
      $('#error-comment-length').hide()
      inputComment.parents('.form-group').removeClass('has-error')
      $('#ref_entries_new .form-control:required').each(function() {
        if (!$(this).val()) $(this).parents('.form-group').addClass('has-error')
        else $(this).parents('.form-group').removeClass('has-error')
      })
      $('input#submit-option').val(submitOption)
      if ($('#ref_entries_new')[0].checkValidity()) $('form#ref_entries_new').submit()
    }
  }) // modit

  // This second modit module was put into the same file because for some reason the dependency was not being resolved
  // properly between it and the first module. i.e. referralEntriesNewModule had not yet been defined. Could not nwork out why

  // Module to handle loading the appropriate RSL Codes for the referral entry form dependent on the location and sis special entyr and child fieldss
  if ($('select#ref_entry_rsl_code_id').length == 0) return
  modit('calmApp.rslCodesLookup', ['calmApp.referralEntriesNew'], (referralEntriesNewModule) => {
    const $rslCodeInput = $('select#ref_entry_rsl_code_id')
    const $placeInput = getLocationInput()
    const $spEntryInput = getSpEntryInput()
    const $childInput = $('#new_ref_entry_child')
    const refEntryRslCodeId = $rslCodeInput.data('refEntryRslCodeId')
    const $rslCodeNotAvailAlert = $('#rsl-code-not-available')
    const $descriptionEl = $('#selected-rsl-code-description')

    $('select#new_ref_entry_assignor_id').select2()

    let descriptionsMap = {}
    $placeInput.on('change', loadRslCodes)
    $spEntryInput.on('change', loadRslCodes)
    $childInput.on('change', loadRslCodes)
    loadRslCodes() // do this on initialization to trigger loading of rsl codes, then listen for changes to trigger loading new ones
    function loadRslCodes() {
      const ajaxUrl = $rslCodeInput.data('ajaxLoadOptionsUrl')
      const child = ($childInput.is(':checked') || $childInput.val() === 'true') // child may be a hidden field and if so it will not have checked attribute but will have a value attr
      const opts = { location_id: $placeInput.val(), for_special_list: $spEntryInput.val(), ref_entry_rsl_code_id: refEntryRslCodeId, child }
      $.ajax({
        url: ajaxUrl,
        type: 'GET',
        dataType: 'json',
        data: opts,
        success(data, textStatus, xhr) {
          descriptionsMap = data.descriptions_map
          drawOptions(data.rsl_code_options, data.ref_entry_rsl_code_str, refEntryRslCodeId, $rslCodeInput, $rslCodeNotAvailAlert, opts)
        },
        error: calmApp.ajaxUtils.handleAjaxError
      })
    }
    // @param refEntryRslCodeStr is a description of the existing rsl code for this ref entry so that if the options do not contain it, we can alert the user that it is not available at the current location
    function drawOptions(rslCodeOptions, refEntryRslCodeStr, refEntryRslCodeId, $rslCodeInput, $rslCodeNotAvailAlert, opts) {
      $rslCodeInput.find('option').remove().select2().trigger('change') // clear the options before redrawing and trigger select2 change so that the removal propogates to the select2 UI
      $rslCodeNotAvailAlert.hide()
      let selectedFound = false
      for (let i = 0; i < rslCodeOptions.length; i++) {
        const rslCodeOptionArray = rslCodeOptions[i]
        const rslCodeId = rslCodeOptionArray[1]
        const selected = rslCodeId == refEntryRslCodeId
        if (selected) {
          selectedFound = true
        }
        const text = rslCodeOptionArray[0]
        const $opt = $('<option>', { value: rslCodeId, text, selected, title: lookupDescription(rslCodeId), 'data-toggle': 'tooltip', 'data-html': 'true' })
        $rslCodeInput.append($opt)
      }
      if (!selectedFound) {
        clearDescription()
        $rslCodeInput.prepend($('<option>', { value: null, text: '', selected: true }))
        if (refEntryRslCodeId) {
          $rslCodeNotAvailAlert.html(`${refEntryRslCodeStr} ${$rslCodeInput.data('codeNotAvailTrans')}`).show().effect('highlight', {}, 1000)
        }
        if (rslCodeOptions.length == 0) {
          setNoCodesFoundDescription(opts)
        }
      }
      $rslCodeInput.select2().trigger('change') // without this the tooltips do not show on the select options
      removeTooltipFromSelected()
    }

    $rslCodeInput.on('change', () => {
      const selectedRslCodeId = $rslCodeInput.val()
      if (selectedRslCodeId) {
        $('#generic-rsl-code-hint').hide()
        $rslCodeNotAvailAlert.hide()
      } else {
        $('#generic-rsl-code-hint').show()
        $rslCodeNotAvailAlert.show()
      }
      setDescription(selectedRslCodeId)
    })

    // when an option is selected the tooltip is redrawn and must be removed again (we do not need the tooltip on the selected one because it is drawn below in the hint PLUS it was not changing properly so better to removeIt)
    $rslCodeInput.on('select2:select', (e) => {
      removeTooltipFromSelected()
    })

    function removeTooltipFromSelected() {
      $('.select2-selection__rendered').each(function(index) {
        const selectedId = $(this).attr('id')
        $(`#${selectedId}`).removeAttr('title')
      })
    }
    // when the drop down is opened, set the tooltips for the options enabling html in the tooltip
    $rslCodeInput.on('select2:open', (e) => {
      setTimeout(() => {
        $('.select2-results__option').each(function(index) {
          $(this).data('toggle', 'tooltip').data('html', 'true').tooltip()
        })
      }, 200)
    })

    function lookupDescription(rslCodeId) {
      return descriptionsMap[rslCodeId]
    }

    function setDescription(rslCodeId) {
      if (!descriptionsMap) {
        return
      }
      const desc = lookupDescription
      const highlight = true
      if (desc) {
        if (highlight) {
          $descriptionEl.html(descriptionsMap[rslCodeId]).effect('highlight', {}, 1000)
        } else {
          $descriptionEl.html(descriptionsMap[rslCodeId])
        }
      } else {
        clearDescription()
      }
    }
    function clearDescription() {
      $descriptionEl.text('')
    }
    function setNoCodesFoundDescription(opts) {
      const notYetSelStr = $rslCodeInput.data('notYetSelTrans')
      const specStr = opts.for_special_list === '' ? notYetSelStr : opts.for_special_list
      let locStr = $placeInput.find(`option[value=${opts.location_id}]`).text()
      if (typeof (locStr) === 'string') {
        locStr = locStr.replace(/-/g, '')
      }
      if (locStr == '') {
        const $readOnlyPlace = $('.location_value')
        const readonlyPlaceTxt = $readOnlyPlace.text()
        if (readonlyPlaceTxt && readonlyPlaceTxt.indexOf($readOnlyPlace.data('noneSelectedText').trim()) == -1) {
          locStr = readonlyPlaceTxt
        } else {
          locStr = notYetSelStr
        }
      }
      const childStr = opts.child
      $descriptionEl.text(`${$rslCodeInput.data('codeNotAvailForTrans')} ${locStr}, ${specStr}, ${childStr}. ${$rslCodeInput.data('plsAdjustFieldsForCode')}`).effect('highlight', {}, 1000)
    }
  }) // modit('calmApp.rslCodesLookup ..
}
