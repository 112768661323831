// declare var $ : any;
// declare var gon : any;
import 'bootstrap/js/modal'
import initQuotaTree from 'components/quota_tree'
import initPersonTab from 'jano/components/people/person_details_tab'
import initDanaTab from 'components/danas/form'
import initDanaTable from 'components/danas/dana_datatable'
import initDay0 from 'components/course_applications/day_0_tab'
import ConfirmationState from 'components/course_applications/ConfirmationState.vue'
import ReferralState from 'components/course_applications/ReferralState.vue'
import AvailableLetterTemplates from 'components/letters/AvailableLetterTemplates.vue'
import LettersTable from 'components/letters/LettersTable'
import Dialog from 'primevue/dialog'
import RoomMixin from 'components/rooms/room-mixin'

export default {
  mixins: [RoomMixin],
  data: {
    course_application: {},
    course_participation: undefined,
    registrars_note: undefined,
    at_note: undefined,
    priority_note: undefined,
    priority_note_editable: false,
    dupl_note_flag: undefined,
    other_notes: false,
    scratch_pad: '',
    referral_state: null, // not used, just for template to compile
    active_letter_tags: []
  },
  computed: {
    isServer() { return ['server_ft', 'server_pt'].includes(this.course_participation) },
    isNew() { return this.course_participation == 'new_student' },
    isOld() { return !this.isNew },
    showScratchPad() {
      return this.scratch_pad
        || this.other_notes
        || this.registrars_note
        || this.at_note
        || (this.priority_note && this.priority_note.length > 0)
    }
  },
  created() {
    this.course_application = gon.course_application
    this.active_letter_tags = gon.active_letter_tags
    this.priority_note_editable = gon.priority_note_editable
  },
  mounted() {
    this.other_notes = $('.note-alert').length > 0
    if (gon.create_new_person) $('#detach-action').click()

    $('a[role=tab][href="#letters"]').on('shown.bs.tab', () => this.$refs.lettersTable.onShow())

    initQuotaTree()
    calmApp.peopleSearch.initialize()
    initPersonTab()
    initDanaTab()
    initDanaTable()
    initDay0()
    calmApp.unsavedChanges.initUnsavedChanges()
    calmApp.datepicker.initializeAfterVueMounted()
  },
  methods: {
    updateLettersDatatable(openLastAdded = false) {
      if (this.$refs.lettersTable) this.$refs.lettersTable.fetchLetters(openLastAdded)
    },
    updatePriorityNote(val, editable) {
      this.priority_note = val
      this.priority_note_editable = editable
    },
    updateAppState(data) {
      if (this.$refs.confirmationStateComponent) this.$refs.confirmationStateComponent.updateStateData(data)
      if (this.$refs.referralStateComponent) this.$refs.referralStateComponent.updateStateData(data)
      this.updateActiveTags(data.active_letter_tags)
    },
    updateActiveTags(tags) {
      this.active_letter_tags = tags || []
    },
    tagDescription(tag) {
      return this.$t(`lookups.letter_tag.description.${tag}`)
    },
    onStateChanged(newState) {
      if (window.quotaTree) window.quotaTree.retrieveQuotaTreeAjax()
      if (this.$refs.availableEmailsComponent) this.$refs.availableEmailsComponent.onStateChanged(newState)
      if (this.$refs.availableSmsComponent) this.$refs.availableSmsComponent.onStateChanged(newState)
      this.course_application.place_reserved = newState.placeReserved
      if (!newState.placeReserved) this.course_application.room_id = null
    }
  },
  components: { ConfirmationState, AvailableLetterTemplates, LettersTable, ReferralState, DialogComponent: Dialog }
}
