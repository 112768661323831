<template>
  <div class="save-controls" :class="{ modified: modified }">
    <div class="unsaved-alert">
      {{ $t('lookups.unsaved_changes_alert') }}
      <a href="#" @click.prevent="$emit('undo')">
        {{ $t('lookups.cancel_unsaved_changes') }}
      </a>
    </div>
    <Button
      type="button"
      class="p-button-success p-button-lg p-button-icon-only button-save"
      :icon="'pi ' + (loading ? 'pi-spin pi-spinner' : 'pi-save')"
      :disabled="!modified || loading"
      @click="$emit('save')"
    />
  </div>
</template>

<script>
import Button from 'primevue/button'

export default {
  components: { Button },
  props: {
    modified: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  emits: ['save', 'undo']
}
</script>

<style scoped lang="scss">
.save-controls {
  position: fixed;
  top: 108px;
  margin-top: 7px;
  right: 30px;
  z-index: 99;
  padding: 10px;
  display: flex;
  align-items: center;

  &.modified {
    box-shadow: -1px 1px 8px 1px rgba(0, 0, 0, 0.2);
    background-color: #FFd497;
    border: 1px solid #80808040;
    border-radius: 5px;

    .unsaved-alert {
      display: inline-block;
    }
  }

  .unsaved-alert {
    width: auto;
    max-width: 270px;
    display: none;
    font-size: 85%;
    padding: 0 10px;
  }
}
</style>
