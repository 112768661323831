<template>
  <div class="confirmation-state">
    <div class="app-state-dropdown">
      <!-- Helper Flow Diagram -->
      <a class="app-state-helper icon-link" target="_blank" href="/en/flow_help">
        <i class="fa fa-question-circle" title="Application Flow Diagram" />
      </a>
      <!-- Current State -->
      <a id="current-conf-state" data-toggle="dropdown" href="#" role="tab">
        <span class="name">{{ stateName }}</span>
        <b class="caret" />
      </a>
      <!-- List of Actions -->
      <ul class="dropdown-menu action-list" :class="{'waiting-list': !placeReserved}">
        <!-- Change state actions -->
        <li
          v-for="(data, action) in actions"
          v-show="allowedActions.includes(action)"
          :key="data.translation"
        >
          <button
            class="btn"
            type="button"
            :class="data.group_code"
            :data-action="action"
            @click="changeState"
          >
            {{ data.translation }}
          </button>
        </li>
        <li v-can:manually_change_confirmation_state:course_applications>
          <button class="btn special" type="button" @click="showManualStateChanger">
            {{ $t('lookups.course_application_actions.manual_state_change') }}
          </button>
        </li>
        <li v-can:conf_state_history:course_applications>
          <button v-remote-modal="true" class="btn special" type="button" :href="stateHistoryPath">
            {{ $t('lookups.course_application_actions.show_state_history') }}
          </button>
        </li>
      </ul>
    </div>

    <div v-if="waitListRank" class="wait-list-rank">
      <a :href="waitListReorderPath" :title="$t('menus.waiting_list_reorder')">
        {{ waitListRank }}
      </a>
    </div>

    <BaseModal ref="lettersDialog" :title="$t('messages.confirmation_state.choose_letter_to_send')">
      <button class="btn btn-block" @click="changeStateWithoutLetter">
        {{ $t('lookups.course_application.send_no_letter') }}
      </button>
      <AvailableLetterTemplatesTable :letter-templates="letterTemplates" />
      <p class="help-block">
        {{ $t('messages.confirmation_state.choose_letter_to_change_state') }}
      </p>
    </BaseModal>

    <BaseModal
      ref="manualStateChanger"
      :title="$t('lookups.course_application.manually_change_state')"
    >
      <div class="change-conf-state-manually">
        <ul class="action-list">
          <li
            v-for="(data, confState) in confStates"
            :key="data.translated_name"
            style="list-style:none;"
          >
            <div class="row">
              <div class="col-md-4">
                <button
                  class="btn"
                  type="button"
                  :class="data.tab_included_in"
                  :data-conf-state="confState"
                  @click="changeStateManually"
                >
                  {{ data.translated_name }}
                </button>
              </div>
              <div class="col-md-8">
                <p class="help-block">
                  {{ data.explanation }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AvailableLetterTemplatesTable from 'components/letters/AvailableLetterTemplatesTable'

export default {
  components: { AvailableLetterTemplatesTable },
  props: {
    appId: {
      type: Number,
      default: 0
    },
    courseId: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    actions: {
      type: Object,
      default: () => ({})
    },
    initialData: {
      type: Object,
      default: () => ({})
    },
    confStates: {
      type: Object,
      default: () => ({})
    },
    forAt: Boolean,
    onRsl: Boolean,
    onSl: Boolean,
    onRl: Boolean,
    gdprRsl: Boolean
  },
  emits: ['stateChanged'],
  data() {
    return {
      state: '',
      stateName: '',
      allowedActions: [],
      placeReserved: false,
      letterTemplates: [],
      waitListRank: ''
    }
  },
  mounted() {
    // we provide initial values from the view to avoid make an ajax request to initialize them
    this.updateStateData(this.initialData)
  },
  computed: {
    stateHistoryPath() {
      return this.$routes.course_application_conf_state_history_path(this.appId)
    },
    waitListReorderPath() {
      return this.$routes.event_course_applications_reorder_path(
        this.courseId,
        this.category,
        { context: 'course_app', context_id: this.appId }
      )
    }
  },
  methods: {
    changeState(event) {
      this.currentStateAction = event.target.dataset.action
      $.post(
        this.$routes.course_application_change_confirmation_state_path(this.appId),
        { confirmation_state_event: this.currentStateAction },
        (data) => {
          if (data.letter_templates) {
            this.letterTemplates = data.letter_templates
            this.$refs.lettersDialog.show()
          } else {
            this.updateStateData(data)
            this.$root.updateLettersDatatable(false)
          }
        },
        'json'
      )
    },
    showManualStateChanger() {
      this.$refs.manualStateChanger.show()
    },
    changeStateManually(event) {
      const toConfState = event.target.dataset.confState
      $.post(this.$routes.course_application_manually_change_confirmation_state_path(this.appId), {
        new_confirmation_state: toConfState,
        registrars_note: 'no registrars note, not yet implemented'
      }, (data) => {
        this.updateStateData(data)
        this.$root.updateLettersDatatable(false)
        this.$refs.manualStateChanger.hide()
      }, 'json')
    },
    changeStateWithoutLetter() {
      $.post(this.$routes.course_application_change_confirmation_state_path({ id: this.appId }), {
        confirmation_state_event: this.currentStateAction,
        bypass_templates: true
      }, (data) => {
        this.updateStateData(data)
        this.$root.updateLettersDatatable(false)
        this.$refs.lettersDialog.hide()
      }, 'json')
    },
    updateStateData(data) {
      this.state = data.confirmation_state
      this.stateName = data.confirmation_state_description
      this.allowedActions = data.allowed_state_actions || []
      if (!this.$can('reset_confirmation_state', 'course_applications')) {
        this.allowedActions = this.allowedActions.filter(
          (item) => item !== 'reset_confirmation_state'
        )
      }
      this.placeReserved = data.place_reserved
      this.waitListRank = data.wait_list_rank
      this.$root.updatePriorityNote(data.priority_note, data.priority_note_editable)
    }
  },
  watch: {
    stateName(newVal, oldVal) {
      if (oldVal && newVal != oldVal) {
        this.$emit('stateChanged', { name: newVal, placeReserved: this.placeReserved })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .confirmation-state {
    display: flex;
    justify-content: space-between;
  }

  .wait-list-rank {
    margin-top: 5px;
    font-size: 20px;
  }

  .app-state-buttons {
    margin-bottom: 15px;
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .app-state-dropdown, .change-conf-state-manually
  {
    position: relative;
    margin-bottom: 25px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    #current-conf-state {
      padding-left: 10px;
      font-size: 20px;
      flex: 1 auto;
      display: flex;
      &:hover, &[aria-expanded] { text-decoration: none; }
      .caret {
        margin-left: 5px;
      }
    }
    .app-state-helper{
      font-size: 16px;
    }
    .action-list {
      padding: 5px;
      li:not(:last-child) {
        margin-bottom: 3px;
      }
      button {
        width: 100%;
        &.enrolled_reserved,
        &.ambiguous { background-color: var(--confirmation-state-enrolled-color); }

        &.special { background-color: var(--confirmation-state-special-color); }
        &.on_wait_list { background-color: var(--confirmation-state-waitlist-color); }
        &.cancelled_etc { background-color: var(--confirmation-state-cancelled-color); }
      }

      &.waiting-list button.ambiguous {
        background-color: var(--confirmation-state-waitlist-color); }
    }
  }
  button.spaced-btn {
    margin-left: 15px;
  }
</style>
