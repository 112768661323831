export default function() {
  $('.profile-anchor').each(function() {
    const id = $(this).attr('id')
    const text = $(this).siblings(`[data-anchor='${id}']`).html()
    const link = $('<a>', { href: `#${id}`, text })
    $('#links-to-profiles').append(link)
    $('#links-to-profiles').append($('<br>'))
  })
  const datatableSettings = {
    ...calmApp.dtSettings.getCalmDatatableSettings(),
    ...{
      language: calmApp.dtSettings.getDTLangTranslations(),
      columns: [
        {},
        {},
        {},
        {}
      ],
      responsive: { breakpoints: calmApp.dtSettings.getResponsiveBreakpoints() },
      order: [2, 'desc'],
      stateSave: false,
      pageLength: 100,
      filter: false // actually might be nice but was putting the search inputs all clumped together at top of page, so maybe enable and fix that
    }
  }

  const tableContainers = $('.profile-role-datatable')
  tableContainers.each(function() {
    $(this).dataTable(datatableSettings)
  })
}
