export default function() {
  const tableContainer = $('#event-templates-datatable')
  const eventTemplatesDataTableSettings = {
    ...calmApp.dtSettings.getCalmDatatableSettings(),
    ...{
      language: calmApp.dtSettings.getDTLangTranslations(),
      columns: [
        { className: 'max-width-200 allow-word-wrap' },
        { className: 'max-width-200 allow-word-wrap' },
        { orderable: false }
      ],
      responsive: { breakpoints: calmApp.dtSettings.getResponsiveBreakpoints() }
    }
  }
  tableContainer.dataTable(eventTemplatesDataTableSettings)
}
