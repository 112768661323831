import 'vendors/jquery'
import 'jquery-ujs'
import 'vendors/remotipart'
import 'bootstrap'
import '@hotwired/turbo-rails'

// Primevue icons - load with webpack so the icon font gets properly loaded
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/lara-light-blue/theme.css'

// import pages script which will be automatically lodaded base on the page name
import { importAll } from 'helpers/core/module-auto-loader'
import registerComponents from 'helpers/core/register-base-components'

import initAllJsAfterContentLoaded from 'helpers/core/init-all-js-after-content-loaded'

// Global helpers loaded for every pages
import 'helpers/core/i18n'

// Register custom filter from PrimeVue
import { FilterService } from 'primevue/api'
import { stripTags } from 'helpers/string_helper'

// Stylesheets
// most of stylesheets are loaded by sprocket using application.css
// We are using webpack to load this one cause sprocket use an outdated version
// of saas and there were a compilation issue
import 'registration-variables.scss'

window.Routes = require('routes.js.erb') // see js-routes gem

window.Turbo.config.forms.mode = 'optin'

// expose jquery to browser and to old javascript code
window.$ = window.jQuery = $

window.registerGlobalVueComponents = (app) => {
  registerComponents(app, require.context('components', true, /[A-Z]\w+\.vue$/))
}

importAll(require.context('pages', true, /.*\.(ts|js|vue)$/))
importAll(require.context('jano/pages', true, /.*\.(ts|js|vue)$/))
importAll(require.context('comm/pages', true, /.*\.(ts|js|vue)$/))
importAll(require.context('news/pages', true, /.*\.(ts|js|vue)$/))
importAll(require.context('appl/pages', true, /.*\.(ts|js|vue)$/))

const blankFilter = function(filter) {
  return filter === undefined
         || filter === null
         || (typeof filter === 'string' && filter.trim() === '')
}

// Custom contains handling HTML values
FilterService.register('calmContains', (value, filter, filterLocale) => {
  if (blankFilter(filter)) return true
  if (value === undefined || value === null) return false
  let matchModeContains = true
  // Handles negative searcg by starting search with "!"
  if (filter[0] == '!') {
    filter = filter.slice(1)
    matchModeContains = false
  }
  const contains = FilterService.filters.contains(stripTags(value), stripTags(filter), filterLocale)
  return matchModeContains ? contains : !contains
})
// Phone filter remove leading zero so "067745" will match witch "+336 77 45"
FilterService.register('phone', (value, filter, filterLocale) => {
  if (blankFilter(filter)) return true
  if (value === undefined || value === null) return false
  return FilterService.filters.contains(value, filter.replace(/^[0]+/g, ''), filterLocale)
})

window.turboLoaded = false

// We had some weird error for some users, where turbo:load was fired twice,
// provoking javascripts issues #6228
// To fix this we use custom calm:turbo:load, which we ensure to fired only once
document.addEventListener('turbo:before-render', (e) => {
  window.turboLoaded = false
})

document.addEventListener('turbo:load', (e) => {
  if (!window.turboLoaded) {
    document.dispatchEvent(new Event('calm:turbo:load'))
    initAllJsAfterContentLoaded('.vue-container')
    window.turboLoaded = true
  } else {
    console.warn('Preventing loading turbo twice')
  }
})
