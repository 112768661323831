<!-- eslint-disable vue/multi-word-component-names -->
// Select2 Component. You can use it with simple form : = f.input :my_input, as: :vselect2
<template>
  <select>
    <slot />
  </select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, Array, Object, Boolean],
      required: false,
      default: () => ({})
    },
    options: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  emits: ['update:modelValue'],
  mounted() {
    this.$emit('update:modelValue', $(this.$el).val())
    const selectConfig = { data: this.options }
    if ($(this.$el).hasClass('select2-html-options')) {
      selectConfig.templateResult = function(d) { return $(d.text) }
      selectConfig.templateSelection = function(d) { return $(d.text) }
    }

    $(this.$el).select2(selectConfig).on('change', () => {
      this.$emit('update:modelValue', $(this.$el).val())
    })

    // Fix initial vselect2 data (see vselect2_input.rb)
    let initialData = $(this.$el).data('initial-value')
    if (typeof initialData === 'string') {
      initialData = initialData.split(',')
      $(this.$el).val(initialData).trigger('change')
    }

    // PRESERVE CHOICE ORDER
    // if you want the order of items chosen by user to be preserved
    // then add the class select2-preserve-order to the select input
    // Example from /edit_config.haml

    // NOTE - you also have to massage the options and put the chosen
    // persisted options in the correct order at top of the collection
    // see how the collection is manipulated in app/views/locations/edit_config.haml
    // for the the enrolment_top_choice_countries select as an example
    function preserveOrderOnSelect2Choice(e) {
      const { id } = e.params.data
      const option = $(e.target).children(`[value=${id}]`)
      option.detach()
      $(e.target).append(option).change()
    }
    const poSelect2s = $('select.select2-preserve-order').select2()
    poSelect2s.each(function() {
      $(this).on('select2:select', preserveOrderOnSelect2Choice)
    })
  },
  unmounted() { $(this.$el).off().select2('destroy') },
  watch: {
    modelValue(value) { $(this.$el).val(value).trigger('change.select2') },
    options(options) { $(this.$el).empty().select2({ data: options }) }
  }
}
</script>
