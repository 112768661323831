import 'select2'

export default {
  el: '#new-internal',
  data: {
    message_type: null,
    cct_at_recipient_option: null
  },
  mounted() {
    $('#internal_message_recipient_id').select2({
      minimumInputLength: 2,
      ajax: {
        url: $('#internal_message_recipient_id').data('url'),
        dataType: 'json'
      },
      escapeMarkup(m) {
        return m // so we can show the away text in red (AWAY until 31 Oct 21) refs #4964
      }
    })
    $('.switch-toggle input[checked]').trigger('click') // fix switch when loaded by ajax
  }
}
