export default function() {
  const SearchForm = $('#courses-index-criteria-search-form')
  const SearchFormSelectors = {
    start_date: { operator: '#operator_start_date', criterion: '#criterion_start_date' },
    course_type: {
      operator: '#operator_course_type',
      criterion: '#criterion_course_type',
      isMultiSelect: true,
      validateOption: true
    },
    venue_id: {
      operator: '#operator_venue_id',
      criterion: '#criterion_venue_id',
      isMultiSelect: true,
      validateOption: true
    },
    registrar_id: {
      operator: '#operator_registrar_id',
      criterion: '#criterion_registrar_id',
      isMultiSelect: true,
      validateOption: true
    },
    missing_ats: {
      operator: '#operator_missing_ats',
      criterion: '#criterion_missing_ats',
      validateOption: true
    }
  }
  const FilterMessageSelectors = {
    venue_id: 'span#venue_id_label',
    start_date: 'span#start_date_label',
    course_type: 'span#course_type_label',
    registrar_id: 'span#registrar_id_label',
    missing_ats: 'span#missing_ats_label'
  }
  const AllTransStr = SearchForm.data('allTrans') // get the translation of 'all'
  const ContextSelect = $('select#context')

  let userCustomSearch = initCustomSearch()
  updateFiltersUI()

  // SETUP the datatable to work asynchronously

  const tableContainer = $('#courses-datatable')
  const defaultSettings = _.merge(
    {},
    calmApp.dtSettings.getCalmDatatableSettings(),
    calmApp.dtSettings.getAjaxDTSettings()
  )
  // the settings required specifically for the users table
  const coursesDataTableSettings = _.merge(defaultSettings, {
    language: calmApp.dtSettings.getDTLangTranslations(),
    stateSave: false,
    responsive: { breakpoints: calmApp.dtSettings.getResponsiveBreakpoints() },
    searching: false,
    pageLength: parseInt(userCustomSearch.length),
    displayStart: parseInt(userCustomSearch.start),
    order: [0, 'asc'],
    ajax: {
      url: $('#courses-datatable').data('source'),
      data(data) {
        userCustomSearch = calmApp.filterDatatableUtils.extractCriteriaParams(
          AllTransStr,
          SearchFormSelectors,
          userCustomSearch
        )
        userCustomSearch.context = ContextSelect.is(':visible') ? ContextSelect.val() : ''
        data.user_custom_search = userCustomSearch // add our own paramaters from the criterion search drop down
        updateFiltersUI()
        bindDeselectFilterEls()
      },
      error(request, status, error) {
        calmApp.ajaxUtils.handleAjaxError(
          request,
          status,
          error,
          'There was an error retrieving courses.'
        )
      }
    },
    columns: [
      { width: '1%', className: '' }, // Start Date
      { width: '1%', className: 'max-width-200 allow-word-wrap' }, // End Date
      { orderable: true, width: '8%' }, // Type
      { width: '14%', className: 'max-width-200 allow-word-wrap' }, // Venue
      { orderable: false, width: '6%' }, // Status
      { orderable: false, width: '10%' }, // Registrars
      { orderable: false, width: '1%', className: 'min-width-80' }, // Vacancies
      { orderable: false, width: '12%', className: 'course-ats-in-datatable' }, // AT's
      { orderable: false, width: '1%', className: '' }, // Lang
      { orderable: false, width: '1%', className: 'text-center' } // Actions
    ],
    sDom: 'C<"clear">lfrtip',
    oColVis: {
      bRestore: true,
      align: 'right',
      exclude: [0, 1, 2, 3, 9],
      buttonText: '',
      fnStateChange(iColumn, visible) {
        const preferencesMap = {
          4: { statusColVis: visible },
          5: { registrarsColVis: visible },
          6: { vacanciesColVis: visible },
          7: { atColVis: visible },
          8: { langColVis: visible }
        }
        const preference = preferencesMap[iColumn] || {}
        calmApp.userPrefUtil.updateUserPreferences({ coursesIndexColumns: preference })
      } // fnStateChange
    },
    // detect if the request has been made through the criteria filter GUI and if so
    // set the saved state of the page number to the first page - re issue #701
    stateLoadParams(settings, data) {
      const customFilterUsed = settings.ajax.indexOf('criteria_search=1') > 0 // this will be in ajax url if criteria search form was used
      if (customFilterUsed) {
        data.start = 0
        data.start = 0
      }
    },
    drawCallback() {
      $('.display-text-in-popup').click(alertBFromDisplayTextButton)
      SearchForm.find("input[type='submit']").prop('disabled', false)
    }
  })
  const oTable = tableContainer.dataTable(coursesDataTableSettings)
  bindPageDataChange()

  function initCustomSearch() {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 11) // include courses starting up to 11 days before
    let result = { length: 10, start: 0 }
    result.operator_start_date = 'gte_date'
    result.criterion_start_date = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`
    // add the saved data to the above defaults
    if (gon.user_custom_search) result = { ...result, ...gon.user_custom_search }
    return result
  }

  function updateFiltersUI() {
    calmApp.filterDatatableUtils.setGuiSearch(
      { user_custom_search: userCustomSearch },
      SearchFormSelectors
    )
    calmApp.filterDatatableUtils.setFilterMessage(
      { user_custom_search: userCustomSearch },
      SearchFormSelectors,
      FilterMessageSelectors
    )
  }

  function bindPageDataChange() {
    $(tableContainer).on('page.dt', (e, settings) => {
      userCustomSearch.start = settings._iDisplayStart
    })
    $(tableContainer).on('length.dt', (e, settings, len) => {
      userCustomSearch.length = len
    })
  }

  function getOTable() {
    return oTable
  }

  function replaceDataTable() {
    oTable.api().ajax.reload() // see the 'ajax' option in the datatables settngs above for how the criteria are added to the ajax request
  }

  function bindCriteriaSearchButtons() {
    $('button#open-criterias').click(() => {
      $('#courses-index-criteria-search').toggle()
    })
    SearchForm.submit((event) => {
      event.preventDefault()
      $('#courses-index-criteria-search').toggle()
      replaceDataTable()
    })
    ContextSelect.on('change', () => {
      filterDeselect('registrar_id')
      replaceDataTable()
    })
  }

  function bindDeselectFilterEls() {
    $('.filterMessage > span.label')
      .unbind()
      .click(function() {
        // HAD to use unbind as this was running multiple times.. could not understand why
        const v = `span#${$(this).attr('id')}`
        const searchSelKey = _.findKey(FilterMessageSelectors, _.partial(_.isEqual, v))
        filterDeselect(searchSelKey)
        replaceDataTable()
      })
  }
  function filterDeselect(searchKey) {
    const selectors = SearchFormSelectors[searchKey]
    if (searchKey === 'start_date') {
      $(selectors.operator).val('gte_date')
    }
    $(selectors.criterion).val('').trigger('change') // trigger change is for select2 plugin to align UI
  }

  bindDeselectFilterEls()
  bindCriteriaSearchButtons()
}
