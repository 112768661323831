export default function() {
  function InitDanaFunctions() {
    if ($('.dana-form-container').length == 0) return

    calmApp.datepicker.initialize()

    $('.add-dana-btn').on('click', function(ev) {
      ev.preventDefault()
      $(this).hide()
      $('.dana-form-container').show()
    })
    // Only authorize numbers, dot and comma in this field
    $('#dana_amount_cents').on('keyup', function() {
      const newVal = $(this).val().replace(/[^0-9^,^\.]/g, '').replace(/\.\.|\.,|,\./g, '.')
        .replace(',,', ',')
      $(this).val(newVal)
    })
    $('#dana_dana_transaction_type_id').change(function() {
      if (($('#dana_bank_account_id > option').length > 1) && ($(':selected', this).parent('optgroup').attr('label') == 'Bank Transactions')) {
        $('#bank_account').show()
      } else {
        $('#bank_account').hide()
      }
    })
    // didn't manage to change size of datepicker out of jquery
    $('#dana_transaction_date').prop('size', '17')

    if (('#tax-return-question-container').length > 0) {
      $("input[name='dana[is_tax_deductible]']").change(function(e) {
        if (this.value == 'false') {
          $('#tax-payer-warning').modal('show')
          e.preventDefault() // to prevent modal scrolling to top of page
        }
      })

      $('#tax-payer-question-no').on('click', () => {
        $('input[name="dana[is_tax_deductible]"][value="true"]').trigger('click')
      })

      $('#tax-payer-question-yes').on('click', () => {
        $('input[name="dana[is_tax_deductible]"][value="false"]').trigger('click')
      })

      $('.radio-inline.disabled').find('input').attr('disabled', 'disabled')
    }
  }// END OF InitDanaFunctions
  InitDanaFunctions()

  // REPORTS
  $('#dana_single_tax_report_select_button').on('click', () => {
    $('#dana_single_tax_report_select').toggle('slow')
  })
  $('#preview_tax_report').on('click', (e) => {
    $('#preview_report').val(true)
    $('#dana_tax_report_pre_run').trigger('submit')
    return false
  })
  $('.create-report').on('click', () => {
    if (!$('#from_start_date').val() || !$('#until_start_date').val()) {
      $('#from_start_date').parent().addClass('has-error')
      $('#until_start_date').parent().addClass('has-error')
      $('#missing-dates-error').show()
      return false
    }
    $('#missing-dates-error').hide()
    $('#dana_single_tax_report_send').trigger('click')
  })

  // DANA LOCATION
  // we move all the event-select option to another div
  // So each time a location is selected, we move back to the event-select
  // only the options linked to that location
  $('#dana-event-select option').appendTo('.event-options-container')

  $('#dana-loc-select').on('change', function(e) {
    e.preventDefault()
    const $locSelect = $(this)

    $('.type-select-container').show()
    $('.event-select-container').hide()
    $('#dana-event-select option').remove()
    $(`.event-options-container option[data-venue-id=${$locSelect.val()}]`).clone().appendTo('#dana-event-select')

    if ($('#dana-event-select option').length) {
      if (!$('#dana-event-select').attr('required')) $('.event-options-container option[value=""]').attr('selected', true).clone().prependTo('#dana-event-select')
      $('#dana-event-select').select2()
      if ($('#dana-type-select option').length > 1) $('#dana-type-select').prop('disabled', false)
      if ($('#dana-type-select').val() == 'course') $('.event-select-container').show()
    } else {
      $('#dana-type-select').val('non_course').prop('disabled', true)
    }

    if ($locSelect.prop('disabled')) return
    $locSelect.prop('disabled', true)
    let url = `${$locSelect.data('url')}?location_id=${$locSelect.val()}`
    url += `&tax_section_active=${gon.tax_section_active}&tax_question_active=${gon.tax_question_active}`
    $.ajax({
      url,
      success(data) {
        if (!$locSelect.hasClass('unique-location')) $locSelect.prop('disabled', false)
        $('.dana-form-container').html('')
        $('.dana-form-container').append(data.html)

        calmApp.datepicker.initialize()
        calmApp.unsavedChanges.bindFormInputs()

        InitDanaFunctions()
      },
      error(request, status, error) {
        calmAjaxUtils.handleAjaxError(request, status, error, 'There was an error retrieving the dana form.')
      }
    })
  })

  $('#dana-type-select').on('change', function(e) {
    const isCourseDana = $(this).val() == 'course'
    $('.event-select-container').toggle(isCourseDana)
    $('#dana-event-select').prop('disabled', !isCourseDana)
  })

  $('#dana-loc-select.unique-location option[value=""]').remove()
  $('#dana-loc-select.unique-location').trigger('change')
  $('#dana-type-select').trigger('change')
}
