<template>
  <tr class="letter-body remote-togglable-row" :class="{display: isActive}">
    <!-- Expandable Content of the Message + Actions buttons -->
    <td class="no-row-click" colspan="7">
      <div v-if="letter.limited_view == true" class="display-more-content">
        <div class="content alert alert-warning">
          {{ $t('lookups.transmission.limited_show_cannot_read_warning') }} {{ letter.topic }}
        </div>
      </div>
      <div v-else class="display-more-content">
        <!-- Actions -->
        <div v-if="Object.keys(letter.actions).length > 0 && !readOnly" class="action-bar">
          <a
            v-if="letter.actions.at_approves"
            v-remote-modal
            class="btn btn-success"
            :href="letter.actions.at_approves.url"
          >
            {{ letter.actions.at_approves.label }}
          </a>
          <a
            v-if="letter.actions.at_refuses"
            v-remote-modal
            class="btn btn-danger"
            :href="letter.actions.at_refuses.url"
          >
            {{ letter.actions.at_refuses.label }}
          </a>
          <a
            v-if="letter.actions.reply"
            v-remote-modal
            class="btn btn-primary"
            :href="letter.actions.reply.url"
          >
            <i class="fa fa-reply" />
            {{ letter.actions.reply.label }}
          </a>
          <a
            v-if="letter.actions.reply_all"
            v-remote-modal
            class="btn btn-primary"
            :href="letter.actions.reply_all.url"
          >
            <i class="fa fa-reply-all" />
            {{ letter.actions.reply_all.label }}
          </a>
          <a
            v-if="letter.in_inbox && letter.is_for_me
              && letter.actions.move_to_at_inbox"
            class="btn btn-default"
            @click="moveToAtInbox"
          >
            <i class="fa fa-share" />
            {{ letter.actions.move_to_at_inbox.label }}
          </a>
          <a
            v-if="letter.actions.draft"
            v-remote-modal
            class="btn btn-default"
            :href="letter.actions.draft.url"
          >
            <i class="fa fa-pencil-alt" />
            {{ letter.actions.draft.label }}
          </a>
          <a v-if="letter.actions.delete" class="btn btn-danger-light" @click="deleteDraft">
            <i class="fa fa-trash" />
            {{ letter.actions.delete.label }}
          </a>
          <template v-if="letter.actions.resolve_tag">
            <div v-for="pendingTag in activeLetterTags" :key="pendingTag" class="btn-group">
              <template
                v-if="!(pendingTag == 'ToAT'
                  && userRoles.includes('teachers') && !userRoles.includes('registrars'))"
              >
                <button
                  class="btn btn-success-light"
                  type="button"
                  @click.prevent="$parent.toggleLetterTag(letter, 'deactivated', pendingTag)"
                >
                  {{ $t('lookups.letter_tag.resolve_tag', {tag: pendingTag}) }}
                </button>
                <button
                  class="only-caret btn btn-success-light dropdown-toggle"
                  data-toggle="dropdown"
                  type="button"
                >
                  <span class="caret" />
                </button>
                <ul class="dropdown-menu">
                  <li @click="$parent.toggleLetterTag(letter, 'associated', pendingTag)">
                    <a>{{ $t('lookups.letter_tag.associate_tag', {tag: pendingTag}) }}</a>
                  </li>
                </ul>
              </template>
            </div>
          </template>
          <div v-if="letter.actions.copy" class="btn-group">
            <button class="btn btn-default" type="button" @click="copyToResend()">
              {{ $t('lookups.message.copy_to_resend') }}
            </button>
          </div>
          <a class="btn btn-default" @click="showDetails = !showDetails">
            {{ $t('buttons.show_details') }}
          </a>
          <a v-if="letter.form_segments" class="btn btn-default" @click="showForm = !showForm">
            {{ showFormLabel() }}
          </a>
        </div>

        <div v-if="letter.attachments.length > 0" class="attachments-container">
          <a
            v-for="attachment in letter.attachments"
            :key="attachment.id"
            v-remote-modal
            class="btn btn-sm btn-default attachment"
            :href="$routes.comm_attachment_path(attachment.id)"
          >
            {{ attachment.name }}
          </a>
        </div>

        <!-- Details -->
        <div v-if="showDetails" class="transmission-details">
          <div
            v-for="attribute in [
              'subject', 'from', 'recipient_address', 'delivery_method', 'status',
              'operator', 'topic', 'inbox_reason', 'removed_from_inbox', 'template_code',
              'template_id', 'id'
            ]"
            :key="attribute"
          >
            <template v-if="letter[attribute]">
              <span class="detail-label">
                {{ $t('activerecord.attributes.message.' + attribute) }}</span>
              <span class="detail-value">{{ displayDetail(attribute) }}</span>
            </template>
          </div>
        </div>

        <!-- FORM -->
        <div v-if="showForm" class="form read-only-text">
          <SegmentsRenderer action="preview" :segments="letter.form_segments" />
        </div>

        <!-- Content -->
        <div class="content read-only-text">
          <SegmentsRenderer action="show" :segments="letter.segments" />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import SegmentsRenderer from 'components/letters/SegmentsRenderer'

export default {
  components: { SegmentsRenderer },
  props: {
    letter: { type: Object, required: true },
    activeLetterId: {
      type: [Number, String],
      default: null
    },
    activeLetterTags: { type: Array, default: () => [] },
    userRoles: { type: Array, default: () => [] },
    readOnly: Boolean
  },
  emits: ['letter-deleted', 'update:letter'],
  data() {
    return {
      showDetails: false,
      showForm: false
    }
  },
  computed: {
    isActive() {
      return this.letter.id == this.activeLetterId
    }
  },
  methods: {
    deleteDraft() {
      calmAlertBootstrap({ type: 'confirm', alertType: 'danger' }).then((confirmed) => {
        if (!confirmed) return
        $.ajax(this.letter.actions.delete.url, {
          type: 'DELETE',
          dataType: 'json',
          success: () => {
            this.$emit('letter-deleted', this.letter)
          }
        })
      })
    },
    moveToAtInbox() {
      $.ajax(this.letter.actions.move_to_at_inbox.url, {
        type: 'PATCH',
        dataType: 'json',
        success: () => {
          this.updateLetterAttributes({ is_for_me: false, recipient_target: 'teachers' })
        }
      })
    },
    copyToResend() {
      calmAlertBootstrap(
        {
          type: 'confirm',
          alertType: 'danger',
          headerText: this.$t('lookups.message.copy_to_resend'),
          messageText: this.$t('lookups.message.copy_to_resend_confirm')
        }
      ).then((confirmed) => {
        if (!confirmed) return
        const url = this.$routes.comm_copy_transmission_path(this.letter.id)
        $.ajax(url, {
          type: 'GET',
          dataType: 'json',
          success: (data) => {
            this.$root.updateAppState(data)
            this.$root.updateLettersDatatable()
          }
        })
      })
    },
    // using v-model:letter, we can update the parent letter with the event update:letter
    updateLetterAttributes(attributes) {
      const newLetter = { ...this.letter, ...attributes }
      this.$emit('update:letter', newLetter)
    },
    displayDetail(attribute) {
      if (attribute == 'recipient_address' && this.letter[attribute] == 'internal') {
        return this.letter.recipients_description
      }
      return this.letter[attribute]
    },
    showFormLabel() {
      return this.letter.form_is_private_msg
        ? this.$t('lookups.message.show_private_msg')
        : this.$t('lookups.message.show_form')
    }
  }
}
</script>

<style lang="scss">
  .letter-body {
    .action-bar {
      // display: flex; //see #4246. For safari the "display:flex" shows a weird button
      margin-bottom: 10px;
      .btn {
        height: 32px;
        line-height: 31px;
        padding: 0 10px;
        i { margin-right: 5px; }
      }
      > .btn, > .btn-group, > span > .btn {
        margin-right: 8px;
        margin-bottom: 5px;
      }
      .btn-group {
        .btn { padding-right: 7px; }
        .only-caret { padding: 0 6px; }
      }
    }
    > td {
      padding: 0 !important;
      border: none !important;
    }
    .display-more-content {
      display: flex;
      flex-direction: column;
      height: 0;
      opacity: 0;
      padding: 0 20px;
      @media(max-width: 800px) { padding: 0 5px; }
      box-sizing: border-box;
      overflow: hidden;
      transition: padding 0.3s, opacity 0.3s;
      .content {
        flex: 1 auto;
        overflow-y: auto;
        margin: 0;
      }
      blockquote {
        display: none;
        margin-top: 10px !important;
      }
      .display-blockquote {
        padding: 0 10px;
        opacity: .8;
        margin-top: 10px;
      }
    }
    &.display .display-more-content {
      height: auto;
      opacity: 1;
      padding: 20px 20px;
      @media(max-width: 800px) {
        padding: 20px 5px;
      }
    }
    .toggle-important {
      margin: 0;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      input { margin: 0 5px 0 0; }
    }
    .attachments-container {
      margin: 0 0 15px 0;
      .attachment {
        margin-right: 8px;
        background-color: #f3f2f3;
      }
    }
  }
</style>
