export default function() {
  const tableContainer = $('#public-files-datatable')
  const ajaxUrl = tableContainer.data('ajaxUrl')
  const $locFilter = $('#public_file_location_filter')

  const defaultSettings = {
    ...calmApp.dtSettings.getCalmDatatableSettings(),
    ...calmApp.dtSettings.getAjaxDTSettings()
  }
  const datatableSettings = {
    ...defaultSettings,
    ...{
      responsive: { breakpoints: calmApp.dtSettings.getResponsiveBreakpoints() },
      language: calmApp.dtSettings.getDTLangTranslations(),
      order: [[0, 'desc']],
      ajax: {
        url: ajaxUrl,
        data(data) {
          data.location_id = $locFilter.val() // add our location filter to the search
        },
        error(request, status, error) {
          calmApp.ajaxUtils.handleAjaxError(
            request,
            status,
            error,
            'There was an error retrieving public files.'
          )
        }
      },
      columns: [
        { className: 'allow-word-wrap', type: 'calm-date-in-data-attr' },
        { className: 'allow-word-wrap' },
        { className: 'allow-word-wrap' },
        {
          className: 'max-width-200 allow-word-wrap no-row-click',
          sortable: false,
          searchable: false
        },
        { className: 'allow-word-wrap', sortable: false, searchable: false },
        { className: 'allow-word-wrap', sortable: false, searchable: false }
      ]
    }
  }
  const oTable = tableContainer.DataTable(datatableSettings)
  $locFilter.on('change', () => {
    oTable.ajax.reload()
  })
}
