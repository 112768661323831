import { get } from 'helpers/core/ajax-utils'

export default {
  data: {
    fetchingRooms: false,
    roomsFetched: false,
    rooms: []
  },
  methods: {
    // Instead of sending rooms with gon, we fetch them asynchronously only when needed
    async fetchRooms() {
      if (gon.rooms) {
        this.rooms = gon.rooms
        this.roomsFetched = true
      }
      if (this.fetchingRooms || this.roomsFetched) return
      this.fetchingRooms = true
      const result = await get(this.$routes.rooms_path({ event_id: gon.event_id || this.course_application.course_id }))
      this.rooms = result.data
      this.fetchingRooms = false
      this.roomsFetched = true
    },
    suitable(room, app) {
      if (!room) return true
      if (room.male_female && room.male_female != app.gender) return false
      if (room.sit_serve && room.sit_serve != app.sitting) return false
      if (room.old_new && room.old_new != app.old) return false
      return true
    },
    appsUsing(roomId, apps) {
      return apps.filter((app) => `${app.room_id}` == `${roomId}`)
    }
  }
}
