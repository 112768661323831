<template>
  <Dropdown
    v-model="(filterModel || {}).value"
    :options="options || []"
    append-to="body"
    @change="filterCallback()"
  />
</template>

<script>
import Dropdown from 'primevue/dropdown'

export default {
  components: { Dropdown },
  props: {
    filterModel: {
      type: Object,
      required: true
    },
    filterCallback: {
      type: Function,
      default: () => {}
    },
    options: {
      type: Array,
      default: () => []
    }
  }
}
</script>
