<template>
  <div>
    <DataTable
      ref="dt"
      v-model:expandedRows="expandedRows"
      v-model:selection="selectedRows"
      :value="reports"
      :loading="loading"
      :lazy="true"
      :total-records="totalRecords"
      :paginator="true"
      :rows="100"
      data-key="id"
      @page="onPage"
      @sort="onSort"
    >
      <template #header>
        <div class="table-header">
          <h2>Content-Security-Policy violation reports</h2>
          <Button
            label="Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            :disabled="!selectedRows || !selectedRows.length"
            @click="confirmDeleteSelected"
          />
        </div>
      </template>

      <template #empty>
        No reports found.
      </template>

      <template #loading>
        Loading reports…
      </template>

      <Column :expander="true" header-style="width: 3rem" />
      <Column selection-mode="multiple" header-style="width: 3rem" />
      <Column
        field="count"
        header="Count"
        :sortable="true"
        header-style="width: 8rem"
        body-style="text-align: right"
      />
      <Column field="created_at" header="First occurrence" :sortable="true" data-type="date">
        <template #body="{ data }">
          {{ formatDate(data.created_at) }}
        </template>
      </Column>
      <Column field="updated_at" header="Last occurrence" :sortable="true" data-type="date">
        <template #body="{ data }">
          {{ formatDate(data.updated_at) }}
        </template>
      </Column>
      <Column field="document-uri" header="Document URI" :sortable="true" body-class="text-break">
        <template #body="{ data }">
          <a v-if="data['document-uri'].startsWith('http')" href="#">
            {{ data["document-uri"] }}
          </a>
          <span v-else>
            {{ data["document-uri"] }}
          </span>
        </template>
      </Column>
      <Column field="violated-directive" header="Violated Directive" :sortable="true" />
      <Column field="blocked-uri" header="Blocked URI" :sortable="true" body-class="text-break">
        <template #body="{ data }">
          <a v-if="data['blocked-uri'].startsWith('http')" href="#">
            {{ data["blocked-uri"] }}
          </a>
          <span v-else>
            {{ data["blocked-uri"] }}
          </span>
        </template>
      </Column>

      <template #expansion="{ data }">
        <dl>
          <dt>Source file</dt>
          <dd>
            {{ data["source-file"] }} {{ data["line-number"] }}:{{ data["column-number"] }}
          </dd>

          <dt>Original policy</dt>
          <dd>
            {{ data["original-policy"] }}
          </dd>

          <dt>Referer</dt>
          <dd>
            {{ data["referrer"] }}
          </dd>

          <dt>User agents</dt>
          <dd>
            <ul>
              <li v-for="user_agent in data['user_agents']" :key="user_agent">
                {{ user_agent }}
              </li>
            </ul>
          </dd>
        </dl>
      </template>
    </DataTable>

    <Dialog v-model:visible="deleteDialog" header="Confirm" :modal="true">
      <div class="confirmation-content">
        Are you sure you want to delete {{ selectedRows.length }} entries?
      </div>
      <template #footer>
        <div class="delete-dialog-actions">
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="deleteDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            autofocus
            @click="deleteSelected"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Button from 'primevue/button'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import { get, destroy } from 'helpers/core/ajax-utils'

export default {

  components: {
    Button,
    Column,
    DataTable,
    Dialog
  },
  data() {
    return {
      deleteDialog: false,
      loading: false,
      reports: null,
      totalRecords: 0,
      expandedRows: [],
      selectedRows: [],
      lazyParams: {}
    }
  },

  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null
    }
    this.fetchReports()
  },

  methods: {
    formatDate(value) {
      const dateValue = (value instanceof Date) ? value : new Date(value)

      return dateValue.toLocaleString()
    },
    fetchReports() {
      this.loading = true

      get('/csp_violation_reports.json', { params: this.lazyParams })
        .then((response) => {
          this.reports = response.data.records
          this.totalRecords = response.data.total_records
        })
        .finally(() => {
          this.loading = false
        })
    },
    onPage(event) {
      this.lazyParams = event
      this.fetchReports()
    },
    onSort(event) {
      this.lazyParams = event
      this.fetchReports()
    },
    confirmDeleteSelected() {
      this.deleteDialog = true
    },
    deleteSelected() {
      this.deleteDialog = false

      destroy('/csp_violation_reports', { data: { ids: this.selectedRows.map((row) => row.id) } })
        .then(() => {
          this.fetchReports()
        })
    }
  }
}
</script>

<style lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.delete-dialog-actions {
  padding-top: 2rem;
  display: flex;
  justify-content: space-around;
}
</style>
