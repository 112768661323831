<template>
  <table class="table letter-templates prevent-clickable">
    <tbody>
      <tr
        v-for="letter in letterTemplates"
        :key="letter.id"
        :class="`available-template_${letter.id}`"
      >
        <td v-if="screen.isSM" class="name">
          {{ letter.name }}
          <div class="text-muted fw-normal mt-1 mb-2">
            {{ letter.description }}
          </div>
          <a
            v-remote-modal="true"
            class="btn btn-primary"
            :href="letter.draft_url"
          >
            {{ $t('formtastic.actions.draft') }}
          </a>
          <a
            v-if="letter.send_directly_url"
            class="btn btn-info"
            :data-url="letter.send_directly_url"
            @click="sendLetterDirectly"
          >
            {{ $t('formtastic.actions.send_directly') }}
          </a>
        </td>
        <template v-else>
          <td class="name">
            {{ letter.name }}
          </td>
          <td class="description">
            {{ letter.description }}
          </td>
          <td class="actions draft">
            <a
              v-remote-modal="true"
              class="btn btn-primary"
              :href="letter.draft_url"
            >
              {{ $t('formtastic.actions.draft') }}
            </a>
          </td>
          <td class="actions send-directly">
            <a
              v-if="letter.send_directly_url"
              class="btn btn-info"
              :data-url="letter.send_directly_url"
              @click="sendLetterDirectly"
            >
              {{ $t('formtastic.actions.send_directly') }}
            </a>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    letterTemplates: {
      type: Array,
      required: true
    }
  },
  methods: {
    sendLetterDirectly(event) {
      event.target.classList.add('disabled')
      $.post(event.target.dataset.url, (data) => {
        $(this.$el).closest('.modal').modal('hide')
        this.$root.updateLettersDatatable(true)
        this.$root.updateAppState(data)
        event.target.classList.remove('disabled')
      }, 'json')
    }
  }
}
</script>

<style scoped lang="scss">
  table {
    margin: 15px 0 !important;
    &.table > tbody > tr {
      border-top: none;
      > td {
        border-top: 1px solid #e8e8e8;
      }
      &:first-child > td {
        border-top: none;
      }
    }
  }
  .name {
    font-weight: bold;
    color: #525252;
  }
  .description {
    font-style: italic;
  }
  .actions {
    text-align: right;
  }
  .btn {
    padding: 2px 10px;
  }
  .btn + .btn {
    margin-left: 15px;
  }
  .available-template_custom-message td {
    border-bottom: 1px solid #525252;
  }
</style>
