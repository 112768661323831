import * as stringSimilarity from 'string-similarity'
import initPhoneInputs from 'components/common/phone-input'

export default function() {
  modit('calmApp.personalTab', ['calmApp.unsavedChanges', 'calmApp.datepicker', 'calmApp.ajaxUtils'], (unsavedChangesModule, calmDatepicker, calmAjaxUtils) => {
    let studentTabDrawn = false

    if (location.hash == '#personal') onTabOpen()
    else setTimeout(checkTabIsOpenned, 500)

    // delay with timeout to let the bootstrap tab use first this event to activate the tab
    $(window).on('hashchange', () => { setTimeout(checkTabIsOpenned, 0) })

    $(document).on('click', 'a[href="#personal"]', () => { onTabOpen() })

    function checkTabIsOpenned() {
      if ($('.tab-pane#personal').is(':visible') && $('#personal-tab-btn').closest('li').hasClass('active')) {
        onTabOpen()
      }
    }

    function onTabOpen() {
      $('.tab-pane#personal').siblings('.tab-pane').removeClass('active')
      $('.tab-pane#personal').addClass('active')
      drawStudentInfo()
    }
    function drawStudentInfo() {
      const url = $('.tab-pane#personal').data('ajaxUrl')
      // only draw student info on first click of tab
      if (!studentTabDrawn && $('#personal-tab-already-drawn').length > 0) {
        studentTabDrawn = true
        initializeTab()
        return
      }
      if (studentTabDrawn || !url) return
      studentTabDrawn = true
      // disable save while loading personal details due to mysterious bug refs #4834
      $('#save-btn').attr('disabled', true)
      $.ajax({
        url,
        type: 'GET',
        dataType: 'json',
        success(data, textStatus, xhr) {
          const cont = $('<div/>').html(data.html)
          $('#personal-tab-content')
            .empty()
            .append(cont)
            .append($('<div>', { style: 'display:none;', id: 'personal-tab-already-drawn' }))
          initializeTab()
          calmApp.datepicker.initialize()
          $('#save-btn').attr('disabled', false)
          $.rails.enableElement($('#save-btn'))
        },
        error(request, status, error) {
          calmAjaxUtils.handleAjaxError(request, status, error, "There was an error retrieving the Person's details form.")
        }
      })
    }

    let givenNameChanged = false
    let givenNameOriginal = ''
    let familyNameChanged = false
    let familyNameOriginal = ''
    let dobChanged = false
    let genderChanged = false

    function initializeTab() {
      unsavedChangesModule.bindFormInputs()
      givenNameOriginal = $('#person_given_name').val()
      familyNameOriginal = $('#person_family_name').val()
      $('#person_given_name').on('change keydown', () => { givenNameChanged = true })
      $('#person_family_name').on('change keydown', () => { familyNameChanged = true })
      $('#dob_exact').on('change', () => { console.log('dob changed'); dobChanged = true })
      $('#person_male').on('change', () => { genderChanged = true })
      $("input[id^='person']").on('change keydown', showEditWarning)
      $("select[id^='person']").on('click', showEditWarning)
      $('input.datepicker').on('change', showEditWarning)

      $('#delete_tax_address').click((e) => {
        $('#tax-address-delete-warning').modal('show')
        e.preventDefault() // to prevent modal scrolling to top of page
      })
      $('#delete_tax_address_yes').on('click', deleteTaxAddress)

      if ($('#course-app-form').length > 0) {
        $('.person-form .hide-in-application-context').remove() // remove cause those field are also present in other tab and can cause conflict
      }

      $('.tab-pane#personal').find('select.select2-default').select2()
      $('.tab-pane#personal').find('select.select2.person-search').select2({
        minimumInputLength: 2,
        ajax: {
          url: $('select.select2.person-search').data('search-url'),
          dataType: 'json',
          processResults: (data) => data,
          cache: true
        },
        escapeMarkup(m) { return m }, // enable HTML in options
        width: '100%'
      }).on('change', (e) => {
        Turbo.visit(`${e.target.dataset.reloadUrl}?person_id=${e.target.value}`)
      })

      calmApp.personForm.disableReadOnlyFormsInputs()
      calmApp.personForm.initializePublicationSubscription()

      initPhoneInputs()

      calmApp.personForm.initializeAddress()
    }

    function showEditWarning(event) {
      // don't do anything for new form of test matching form
      if ($(this).closest('.test-matching-person').length > 0) return
      if ($(this).closest('.new-form').length > 0) return

      // display warning when major changes are done
      const givenNameMajorChange = stringSimilarity.compareTwoStrings($('#person_given_name').val(), givenNameOriginal) < 0.8
      const familyNameMajorChange = stringSimilarity.compareTwoStrings($('#person_family_name').val(), familyNameOriginal) < 0.8
      const majorChanges = givenNameMajorChange || familyNameMajorChange || ((givenNameChanged || familyNameChanged) && (dobChanged || genderChanged))
      const $majorEditPersonWarning = $('#major-edit-person-warning')
      if ($majorEditPersonWarning.length > 0 && !$majorEditPersonWarning.is(':visible') && majorChanges) {
        $majorEditPersonWarning.show()
        alertBootstrap($majorEditPersonWarning.find('.warning-text').text(), $majorEditPersonWarning.find('.title').text())
      }
    }

    function deleteTaxAddress() {
      $('#person_tax_country').val('')
      $('#person_tax_address').val('')
      $('#person_tax_town').val('')
      $('#person_tax_postal_code').val('')
      $('input[name="address"][value="home"]').click()
      unsavedChangesModule.setUnsavedChangesAlert()
    }
  })// modit('calmApp.personalTab'
}
